<template>
  <div>
    <b-button class="m-md-2" size="sm" @click="downloadCSV()"><b-icon icon="file-earmark-spreadsheet-fill"/> Export in CSV</b-button>
  </div>
</template>

<script>
import utils from '@/common/utils.js'

export default {
  name: 'exportbutton',
  data () {
    return {
      jobId: undefined,
      propId: undefined,
      properties: []
    }
  },
  methods: {
    downloadCSV () {
      const self = this
      self.jobId = self.$router.currentRoute.params.jobId
      if (!self.propId) {
        // try to get it
        self.propId = self.$router.currentRoute.params.propId
      }
      const perfdata = self.$store.getters.getPropertyJobTable(self.jobId)
      self.properties = self.$store.getters.properties
      if (self.propId) {
        const csv = self.getCSVData(self.propId, perfdata)
        const blob = new Blob([csv], { type: 'csv/plain' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        const propObj = utils.findProperty(self.propId, self.properties)
        if (propObj) {
          link.download = `PerfJobId${self.jobId}${propObj.name}.csv`
        } else {
          link.download = `PerfJobId${self.jobId}${self.propId}.csv`
        }
        link.click()
      } else {
        // in principle only job id available, we will assume that we are at a timeline view
        const csv = self.getCSVSeverityData(perfdata)
        const blob = new Blob([csv], { type: 'csv/plain' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `SeverityJobId${self.jobId}.csv`
        link.click()
      }
    },
    getCSVData (propId, perfdata) {
      const tdArray = perfdata.tableRowMap.get(propId)
      let csvdata = 'Timestamp,Average'
      for (let i = 0; i < 11; ++i) {
        csvdata += `,Q${i * 10}`
      }
      csvdata += '\n'
      if (tdArray) {
        tdArray.forEach(td => {
          if (td.propQuantiles.length !== 0) {
            csvdata += td.timestamp + ',' + td.propertyAverage + ',' + td.propQuantiles.slice().reverse().join() + '\n'
          }
        })
      }
      return csvdata
    },

    getCSVSeverityData (perfdata) {
      const self = this
      let csvdata = 'Property'
      const properties = Array.from(perfdata.tableRowMap.keys())
      if (properties.length > 0) {
        perfdata.tableRowMap.get(properties[0]).forEach(td => { csvdata += `,${td.timestamp}` })
      }
      csvdata += '\n'
      properties.forEach(propId => {
        const propObj = utils.findProperty(propId, self.properties)
        if (propObj) {
          csvdata += `${propObj.name},`
          perfdata.tableRowMap.get(propId).forEach(td => {
            csvdata += td.severityAvg + ','
          })
          csvdata += '\n'
        }
      })
      return csvdata
    },

    updatePropId (propId) {
      this.propId = propId
    }
  }
}
</script>

<style scoped>

</style>
