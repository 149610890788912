<template>
  <div>
    <b-modal ref="statisticsSearch" title="SNG Statistics Query" size="lg" v-if="projToUsers !== undefined">

      <!-- Check Group Batchdomain -->

      <b-form-group label="Batchdomain:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group v-model="form.data.batchdomain" :options="form.input.batchdomain" class="mb-2" value-field="item" text-field="name" disabled-field="notEnabled" :aria-describedby="ariaDescribedby"/>
      </b-form-group>
      <!-- Users -->
      <div class="ovf">
      <table>
        <tr>
          <td v-for="proj in form.input.projects" :key="proj">
            <ProjectSelect :project="proj" :users="projToUsers.get(proj)"/>
          </td>
        </tr>
      </table>
      </div>
      <!-- Date pickers -->
      <b-form inline>
      <b-form-group label="From End Date" class="mr-2 mt-4">
        <b-form-datepicker v-model="form.data.startDate"></b-form-datepicker>
      </b-form-group>

      <b-form-group label="To End Date" class="mt-4">
        <b-form-datepicker v-model="form.data.endDate"></b-form-datepicker>
      </b-form-group>
      </b-form>

      <b-form-group label="Summary detail:" v-slot="{ ariaDescribedby }" class="mt-4">
      <b-form-radio-group v-model="form.data.interval" :options="form.input.interval" class="mt-2" value-field="item" text-field="name" disabled-field="notEnabled" :aria-describedby="ariaDescribedby"/>
      </b-form-group>

      <!-- Action buttons on the modal footer -->
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" @click="hide">cancel</b-button>
        <b-button variant="info" @click="search">search</b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'statistic-query',
  data () {
    return {
      projToUsers: undefined,
      form: {
        data: {
          batchdomain: [],
          interval: [],
          project: [],
          users: [],
          projectsSelected: new Map(),
          startDate: '',
          endDate: ''
        },
        input: {
          batchdomain: [
            { item: 'fat', name: 'Fat' },
            { item: 'thin', name: 'Thin' }
          ],
          interval: [
            { item: 'all', name: 'Entire interval sum' },
            { item: 'day', name: 'Dayly sums' },
            { item: 'month', name: 'Monthly sums' },
            { item: 'year', name: 'Yearly sums' }
          ],
          projects: [],
          users: []
        }
      }
    }
  },

  mounted () {
    const self = this
    self.projToUsers = new Map([
      ['pr28fa', ['lu43jih', 'di36pec', 'di34faf']],
      ['pr84mi', ['di36zeb2', 'aa22bbc']],
      ['pr74do', ['lu78qer9', 'aa33bbc']],
      ['pr53yi', ['di36zef3', 'aa44bbc']],
      ['pr22ab', ['lu34ism', 'lu35ism', 'lu45ism', 'lu43jih2', 'li43jih3', 'li43okj4', 'de45pec', 'de43erd', 'de23erd']],
      ['pr21ac', ['lu34dei', 'lu34deo', 'lu34dea', 'lu34def']],
      ['pr20ad', ['a239da', 'a209ca']],
      ['pr19af', ['a24ady', 'a45ady']]
    ])
    self.projToUsers.forEach((users, proj) => {
      const projUser = [{ kennung: '#all#', selected: false }]
      users.forEach(user => {
        projUser.push({ kennung: user, selected: false })
      })
      self.form.data.projectsSelected.set(proj, projUser)
      self.form.input.projects.push(proj)
    })
  },

  methods: {
    search () {
      // ToDo dispatch query
    },
    show () {
      this.$refs.statisticsSearch.show()
    },
    hide () {
      this.$refs.statisticsSearch.hide()
    }
  }
}
</script>

<style scoped>
.ovf {
  max-height: 20vh;
  overflow-x: scroll;
  overflow-y: scroll;
}

td {
  vertical-align: top;
}
</style>
