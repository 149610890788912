<template>
  <div>
    <b-form-group>
      <template #label>
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          aria-describedby="users"
          aria-controls="users"
          @change="toggleAll"
        >
          {{ project }}
        </b-form-checkbox>
      </template>

      <template v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          :id="project"
          v-model="selected"
          :options="users"
          :aria-describedby="ariaDescribedby"
          name="flavors"
          class="ml-4"
          :aria-label="project"
          stacked
        ></b-form-checkbox-group>

      </template>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    project: String,
    users: Array
  },
  data () {
    return {
      selected: [],
      allSelected: false,
      indeterminate: false
    }
  },
  methods: {
    toggleAll (checked) {
      this.selected = checked ? this.users.slice() : []
    }
  },
  watch: {
    selected (newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.users.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  }
}
</script>
