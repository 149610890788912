<template>
  <div>
    <b-button class="m-md-2" size="sm" @click="showScript()"><b-icon icon="file-earmark-text"/> Job Script</b-button>
    <b-modal
      ref="jobscriptModal"
      centered
      size="xl"
    >
      <template #modal-title>
        <span class="bold">
        Job {{ jobid }}
        </span>
      </template>
      <div class="d-block text-left">
        <span style="font-family: 'Courier New', monospace; font-size: 12px;">
            <div v-for="(line, idx) in jobscript"
              :key="idx" >
              {{ line }} <br>
            </div>
        </span>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" @click="hideScript()">Cancel</b-button>
      </template>
   </b-modal>
  </div>
</template>

<script>
export default {
  name: 'jobscriptbutton',
  data () {
    return {
      jobid: undefined,
      jobscript: [],
      notavailable: 'Not available'
    }
  },
  async mounted () {
    const self = this
    self.jobid = self.$router.currentRoute.params.jobId
    const jobScript = self.$store.getters.getJobScript(self.jobid)
    if (jobScript !== undefined) {
      self.jobscript = jobScript.split(/\r?\n/)
    } else {
      self.jobscript = [self.notavailable]
    }
  },
  methods: {
    showScript () {
      const self = this
      const jobId = self.jobid
      if (self.jobscript.length === 0 || self.jobscript[0] === self.notavailable) {
        // console.log('Entramos')
        self.$store.dispatch('fetchJobScript', { jobId })
          .then(resolve => {
            // console.log('Entrmos 2.')
            const jobScript = self.$store.getters.getJobScript(self.jobid)
            self.jobscript = jobScript.split(/\r?\n/)
          })
          .catch(_ => {
            self.jobscript = [self.notavailable]
            // console.log('Catch')
          })
          .finally(() => {
            self.$refs.jobscriptModal.show()
            // console.log('Finally')
          })
      } else {
        this.$refs.jobscriptModal.show()
      }
    },
    hideScript () {
      this.$refs.jobscriptModal.hide()
    }
  }
}
</script>

<style scoped>
</style>
