<template>
  <div>
    <div style="display:flex; align-items:center; justify-content: center;"><b-img :src="require('../assets/logo.png')" height="70px" rounded class="mr-4 mt-4 mb-4" id="logo"/> <span id="title" style="text-align: center;">HPC Report</span></div>
    <div class="login-view">
      <b-card class="bkgimg m-3" style="min-width:100rem; min-height: 28rem;">
        <!-- Comment when maintenance is over -->
        <!--b-row><b-col><b-card style="background-color: #f39c12; color: #fff;">This website is on mainenace on the 18th of November 2024! </b-card></b-col></b-row-->
        <!-- Comment until here when maintenance is over -->
        <b-row>
          <b-col/>
          <b-col>
            <b-card header-bg-variant="transparent" border-variant="info" style="max-width:30rem;" class="m-5" >
            <template #header>
              <h4 class="mb-0">Sign in</h4>
            </template>

            <b-form @submit="login" :state="form.state" class="mb-3">
              <!-- Username -->
              <b-input-group
                id="login-form-group-username"
                label-for="login-input-username"
                :state="form.state"
                class="w-50 mx-auto"
              >
              <b-input-group-prepend is-text>
                <b-icon icon="person-fill"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                id="login-input-username"
                v-model="form.username"
                type="text"
                required
                placeholder="Enter username"
                :state="form.state"
              ></b-form-input>
              </b-input-group>

              <!-- Password -->
              <b-input-group
               id="login-form-group-password"
               label-for="login-input-password"
               :state="form.state"
               class="mt-3 w-50 mx-auto"
              >
              <b-input-group-prepend is-text>
                <b-icon icon="lock-fill"></b-icon>
              </b-input-group-prepend>

              <b-form-input
                id="login-input-password"
                v-model="form.password"
                type="password"
                required
                placeholder="Enter password"
                :state="form.state"
                class="mx-auto"
              ></b-form-input>
              </b-input-group>

              <!-- Submit Button -->
              <b-button type="submit" class="mt-3" variant="primary">Login</b-button>

              <!-- Form Feedback Text -->
              <b-form-invalid-feedback id="password-help-block" :state="form.state">
                <span>The provided username and password was not valid</span>
              </b-form-invalid-feedback>
            </b-form>
           </b-card>
         </b-col>
         <b-col/>
       </b-row>
       </b-card>
       <a href="https://servicedesk.lrz.de/" target="_blank">Servicedesk</a> | <a href="https://www.lrz.de/datenschutzerklaerung/" target="_blank">Data Privacy Policy / Datenschutzerklärung</a> | <a href="https://www.lrz.de/impressum/" target="_blank">Legal Notice / Impressum</a>
     </div>
  </div>
</template>

<script>

export default {
  name: 'login',

  data () {
    return {
      form: {
        state: null,
        username: '',
        password: ''
      }
    }
  },

  methods: {
    async login (evt) {
      evt.preventDefault()

      try {
        await window.APIClient.login(this.form.username, this.form.password)
        this.form.state = null
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push({ name: 'Home' })
        }
      } catch (error) {
        this.form.state = false
        // console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* center login form */
.login-view {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

a {
  color: grey;
}

#logo, #title
{
  display: inline;
  font-size: 300%;
  font-family: Verdana, sans-serif;
  color: dimgray;
}

.bkgimg {
 background-image: url('../assets/background.jpg');
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
}
</style>
