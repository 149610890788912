<template>
 <div class="timelineinfo">
   <div v-if="this.$props.info">
   <div class="timelineinfo-title">
    {{ this.$props.info.name }}
   </div>
   {{ this.$props.info.explanation }}
   <div>
   Hint: {{ this.$props.info.hints }}
   </div>
   <div>
   Units: [{{ this.$props.info.unit }}]
   </div>
   <div>
   Measured per {{ this.$props.info.domain }}
   </div>
   </div>
   <div v-else>
     Error: no property information
   </div>
 </div>
</template>

<script>

export default {
  name: 'timeline-info',

  props: {
    info: Object
  }

}
</script>

<style scoped>
  * {
    display: flex;
    flex-direction: column;
  }

  .timelineinfo-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: larger;
    display: flex;
    flex-direction: column;
  }
  .timelineinfo {
    text-align: left;
  }
  .bold {
    font-weight: bold;
  }

</style>
