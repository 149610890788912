
export default {
  findProperty (propId, properties) {
    const propObj = properties.find(prop => {
      return prop.property_type_id === propId
    })
    return propObj
  },

  isEmpty (obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false
      }
    }
    return true
  },

  removeEmptyValues (obj) {
    const result = {}
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        result[key] = obj[key].join(',')
      }
      if (!Array.isArray(obj[key]) && obj[key] !== '') { // Only add keys with non-empty strings
        result[key] = obj[key]
      }
    }
    return result
  },
  humanReadable (val) {
    if (val > 1e9) {
      val = val / 1e9
      if (val % 1 !== 0) val = val.toFixed(4)
      return `${val} G`
    } else if (val > 1e6) {
      val = val / 1e6
      if (val % 1 !== 0) val = val.toFixed(4)
      return `${val} M`
    } else if (val > 1e3) {
      val = val / 1e3
      if (val % 1 !== 0) val = val.toFixed(4)
      return `${val} K`
    }
    return val
  }
}
