import storeFuncts from './storeFuncts.js'

export default {
  hasAccounting (state) {
    return Object.keys(state.accountings).length > 0
  },

  hasJobPerfData (state) {
    return Object.keys(state.jobPerfData).length > 0
  },

  jobs (state) {
    if (window.localStorage.getItem('jobIds')) {
      return JSON.parse(window.localStorage.getItem('jobIds'))
    } else {
      return []
    }
  },

  perfJobs (state) {
    if (window.localStorage.getItem('perfjobs')) {
      return JSON.parse(window.localStorage.getItem('perfjobs'))
    } else {
      return []
    }
  },

  getJobPerfData (state) {
    return function (jobId) {
      return state.jobPerfData[jobId]
    }
  },

  getAllJobPerfData (state) {
    return state.jobPerfData
  },

  accountings (state) {
    return Object.values(state.accountings)
  },

  getJobAccounting (state) {
    return function (jobId) {
      return state.accountings[jobId]
    }
  },

  averageSeverities (state, getters) {
    // calculate average severities
    const result = []
    for (const jid of Object.keys(state.jobPerfData)) {
      const avgrs = getters.averageSeverity(jid)
      if (avgrs) result.push(avgrs)
    }
    return result
  },

  averageSeverity (state) {
    // calculate average severity of one job
    return function (jid) {
      const tsRecord = state.jobPerfData[jid]
      if (tsRecord && tsRecord !== '') {
        const sum = {}
        const averages = {}
        const count = {}
        for (let i = 0; i < tsRecord.length; ++i) {
          const propId = tsRecord[i].property_type_id
          const severity = tsRecord[i].severity_average
          const numObs = tsRecord[i].num_of_observations
          if (sum[propId]) {
            sum[propId] += severity * numObs
            count[propId] += numObs
          } else {
            sum[propId] = severity * numObs
            count[propId] = numObs
          }
        }
        averages.jobId = jid
        Object.keys(sum).forEach(function (key, index) {
          const pid = parseInt(key)
          const propObj = state.properties.find(item => item.property_type_id ===
            pid)
          if (propObj === undefined) {
            // console.log('property name not found for ' + key)
          } else {
            averages[propObj.name] = sum[key] / count[key]
          }
        })
        return averages
      }
      return undefined
    }
  },

  getActivePropInJob (state, getters) {
    return function (jid, averageSeverity) {
      const jobprops = []
      for (const key of Object.keys(averageSeverity)) {
        const propObj = state.properties.find((item) => { return item.name === key })
        if (propObj) jobprops.push(propObj)
      }
      storeFuncts.sortProperties(jobprops, getters.propertyTree)
      return jobprops
    }
  },

  properties (state) {
    return state.properties
  },

  propertyTree (state) {
    return state.propertyTree
  },

  arePropertiesSorted (state) {
    return state.propertiesSorted
  },

  hasProperties (state) {
    return state.properties.length > 0
  },

  hasPropertyTree (state) {
    return state.propertyTree.length > 0
  },

  hasPropertyJobTable (state) {
    return function (jobId) {
      if (state.propertyJobTable[jobId]) {
        return state.propertyJobTable[jobId].length > 0
      }
      return false
    }
  },

  getPropertyJobTable (state) {
    return function (jobId) {
      return state.propertyJobTable[jobId]
    }
  },

  getJobScript (state) {
    return function (jobId) {
      return state.jobScript[jobId]
    }
  },

  arePerfJobsInStore (state) {
    return state.storePerfJobs.length > 0
  },

  getStorePerfJobs (state) {
    return state.storePerfJobs
  },

  getSearchURL (state, getters) {
    const url = window.localStorage.getItem('searchURL')
    if (url) return url
    return ''
  },

  isCanceled (state) {
    return state.cancel
  },

  getMonthlySummary (state) {
    return function (formdata) {
      const key = storeFuncts.monthlySummaryMakeKey(formdata)
      return state.monthlySummary[key]
    }
  },

  getFilteredSearchAccounting (state) {
    return function (formdata) {
      const key = storeFuncts.filteredSearchMakeKey(formdata)
      return state.accountings[key]
    }
  },

  getModules (state) {
    return function (jobId) {
      return state.modules[jobId]
    }
  }
}
