<template>
  <div>
  <b-container fluid>
    <b-row class="timeline-description">
      <b-col cols="2">
        <b-container>
          <b-row><b-col><GoBack/></b-col><b-col/></b-row>
          <b-row><b-col><b-button-group> <JobInfo/><PerfJobInfo/> </b-button-group></b-col></b-row>
        </b-container>
      </b-col>
      <b-col cols="8">
        <timeline-info :info="properties[propIdx]"/>
      </b-col>
      <b-col>
        <b-button size="sm" @click="openSeverityGraphHelp" class="mt-2"><b-icon icon="question-circle" size="sm"/> Graph</b-button>
        <ExportButton/>
        <!--JobScriptButton/-->
      </b-col>
    </b-row>
    <b-row v-if="propertyJobTable">
      <b-col class="p-3"> <div class="ovf">
        <table>
          <tr>
            <td class="textleft stickycol" rowspan="2">
              <div v-html="getHoverTdData(true)"></div>
            </td>
            <td v-for="(thdate, idx) in propertyJobTable.arrayTHDates"
              :key="idx"
              :colspan="thdate.colspan"
              class="textleft">
              {{ thdate.displaydate }}
            </td>
          </tr>
          <tr>
            <td v-for="(hour, idx) in propertyJobTable.arrayTHTimes"
              :key="idx"
              :colspan="hour.colspan"
              class="textleft tlborder">
            {{ hour.displaytime }}
            </td>
          </tr>
          <tr v-for="(prop, idx) in properties" :key="idx">
            <th @mouseover="infoUpdate(idx, undefined)"
              role="button"
              @click="goToPerformance(prop.property_type_id)"
              class="text-left stickycol">
              <nobr><span :style="getColor(prop)"><b-icon icon="circle-fill" class="mr-2"/></span>{{ prop.name }}
              <b-icon icon="caret-right-fill" class="ml-2"/></nobr>
            </th>
            <td class="py-1 maxw-15 textleft"
              v-for="(sev, index) in getSeverityRow(prop)"
              :key="index"
              @mouseover="infoUpdate(idx, sev)">
              <TimelineEvent
                :property="prop"
                :tdData="sev"
                :id="getId(idx, index)"/>
            </td>
          </tr>
          <tr>
            <td class="stickycol" rowspan="2">
              <div v-html="getHoverTdData(false)"></div>
            </td>
            <td v-for="(hour, idx) in propertyJobTable.arrayTHTimes"
              :key="idx"
              :colspan="hour.colspan"
              class="textleft tlborder2">
            {{ hour.displaytime }}
            </td>
          </tr>
          <tr>
            <td v-for="(thdate, idx) in propertyJobTable.arrayTHDates"
              :key="idx"
              :colspan="thdate.colspan"
              class="textleft">
              {{ thdate.displaydate }}
            </td>
          </tr>
        </table> </div>
      </b-col>
   </b-row>
   <b-row class="timeline-description">
      <b-col class="ml-3">
        <timeline-info :info="properties[propIdx]"/>
      </b-col>
   </b-row>
  </b-container>
  <SeverityGraphHelp ref="severitygh"/>
  </div>
</template>

<script>
import errorcommon from '@/common/error.js'

export default {
  name: 'Timeline',

  data () {
    const mc = new Map([
      [-20, [211, 211, 211]],
      [0, [128, 243, 32]],
      [1, [141, 237, 24]],
      [2, [154, 230, 17]],
      [3, [166, 222, 11]],
      [4, [178, 213, 7]],
      [5, [190, 203, 4]],
      [6, [201, 192, 2]],
      [7, [211, 181, 1]],
      [8, [221, 169, 2]],
      [9, [229, 156, 4]],
      [10, [236, 143, 7]],
      [11, [242, 130, 12]],
      [12, [247, 118, 17]],
      [13, [251, 105, 24]],
      [14, [254, 92, 32]],
      [15, [255, 80, 41]],
      [16, [255, 68, 51]],
      [17, [253, 57, 62]],
      [18, [251, 47, 73]],
      [19, [247, 37, 85]],
      [20, [241, 29, 97]]
    ])
    return {
      properties: [],
      propertyTree: [],
      propIdx: 0,
      jobId: '',
      propertyJobTable: [],
      averageSeverities: [],
      mapColor: mc,
      hoverTd: undefined
    }
  },

  mounted () {
    const self = this
    self.jobId = this.$router.currentRoute.params.jobId
    const jobId = self.jobId
    const promises = []
    if (!self.$store.getters.hasProperties) {
      const p1 = self.$store.dispatch('fetchProperties')
      promises.push(p1)
      if (!self.$store.getters.hasPropertyTree) {
        p1.then(r1 => { self.$store.dispatch('fetchPropertyTree') })
      }
    }
    if (!self.$store.getters.getJobPerfData(jobId)) { // performance data
      promises.push(self.$store.dispatch('fetchPerformanceDataFromJob', { jobId }))
    }
    // wait for all promises to finish to get data and initialize
    Promise.all(promises).then(r1 => {
      let p2
      if (!self.$store.getters.hasPropertyJobTable(self.jobId)) {
        p2 = self.$store.dispatch('calculatePropertyJobTable', { jobId })
      }
      return p2
    }).then(r2 => {
      self.propertyTree = this.$store.getters.propertyTree
      self.propertyJobTable = this.$store.getters.getPropertyJobTable(jobId)
      // gets only average severities for our job
      self.averageSeverities = self.$store.getters.averageSeverity(self.jobId)
      self.properties = this.$store.getters.getActivePropInJob(self.jobId, self.averageSeverities)
    }).catch(error => {
      errorcommon.handleError(error, self.$router)
    }).finally(() => {
      self.$emit('timeline', 'Done')
    })
  },

  methods: {
    getId (idx, index) {
      return `${idx}-${index}`
    },

    infoUpdate (idx, td) {
      this.propIdx = idx
      this.hoverTd = td
    },

    getColor (prop) {
      let val = -1
      if (this.averageSeverities) {
        val = this.averageSeverities[prop.name]
      }
      const color = this.numToRgb(val)
      return `color: rgba(${color}); fontsize: large`
    },

    numToRgb (val) {
      const arr = this.mapColor.get(Math.round(val * 20))
      if (arr) return arr
      return [211, 211, 211]
    },

    goToPerformance (propId) {
      this.$router.push({
        name: 'Performance',
        params: { propId: propId }
      })
    },

    getSeverityRow (prop) {
      if (prop && this.propertyJobTable.tableRowMap) {
        return this.propertyJobTable.tableRowMap.get(prop.property_type_id)
      } else {
        return []
      }
    },

    openSeverityGraphHelp (evt) {
      evt.preventDefault()
      this.$refs.severitygh.show()
    },

    getHoverTdData (top) {
      if (this.hoverTd === undefined) {
        let avgdisplay = ''
        if (this.properties[this.propIdx] && this.averageSeverities) {
          const val = this.averageSeverities[this.properties[this.propIdx].name]
          if (val) {
            avgdisplay += 'Average Severity: ' + val.toFixed(4).toString() + '<br>'
          }
        }
        return top ? avgdisplay + 'Click on row or<br>Hover on color map &#8600;' : avgdisplay + 'Click on row or<br>Hover on color map &#8599;'
      }
      return `Average value: ${this.hoverTd.propertyAverage} <br>
              Severity: ${this.hoverTd.severityAvg} <br>
              Observations: ${this.hoverTd.numObs}`
    }
  }
}
</script>

<style scoped>
.timeline-description {
  background-color: #6eaddf;
  min-height: 150px;
}

.textleft {
 text-align: left;
}

.maxw {
  max-width: 400px;
}

.tlborder {
  border-left: 1px solid;
  border-bottom: 1px solid;
}

.tlborder2 {
  border-left: 1px solid;
  border-top: 1px solid;
}

th:hover {
  background-color: rgb(211, 211, 211);
}

.timelineaxis {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  text-align: left;
}

.ovf {
  overflow-x: scroll;
}

.stickycol {
  position: sticky;
  background-color: #ffffff;
  left: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
</style>
