<template>
  <div
    :id="id"
    :style="getEventStyle"
    @click="goToPerformance">
    .
    <!--BPopover
       v-if="tdData.severityAvg !== -1"
       :target="id"
       triggers="hover"
       @show="onShow"
       delay="{ show: 0, hide: 50 }"
    >
      <table class="smfont maxwidth">
        <tr>
          <td> {{ property.name }} </td>
        </tr>
        <tr>
          <td> {{ getNiceTS(tdData.timestamp) }} </td>
        </tr>
        <tr>
          <td> Observations: {{ tdData.numObs }} </td>
        </tr>
        <tr v-for="(sevVal, idx) in severityQuantiles" :key="idx">
           <td :style="getColStyle(sevVal)">
             Q<sub>{{ getQName(idx) }}</sub>: {{ getSeverityPct(sevVal) }}
           </td>
        </tr>
      </table>
    </BPopover-->
    <!--b-tooltip :target="id" triggers="hover">
      <table class="smfont maxwidth">
        <tr>
          <td v-for="(sevVal, idx) in severityQuantiles" :key="idx" :style="getColStyle(sevVal)">
            {{ getSeverityPct(sevVal) }}
          </td>
        </tr>
      </table>
    </b-tooltip-->
  </div>
</template>

<script>

export default {
  name: 'timeline-event',

  props: {
    tdData: Object,
    property: Object,
    id: String
  },

  data () {
    const mC = new Map([
      [-20, [211, 211, 211]],
      [0, [128, 243, 32]],
      [1, [141, 237, 24]],
      [2, [154, 230, 17]],
      [3, [166, 222, 11]],
      [4, [178, 213, 7]],
      [5, [190, 203, 4]],
      [6, [201, 192, 2]],
      [7, [211, 181, 1]],
      [8, [221, 169, 2]],
      [9, [229, 156, 4]],
      [10, [236, 143, 7]],
      [11, [242, 130, 12]],
      [12, [247, 118, 17]],
      [13, [251, 105, 24]],
      [14, [254, 92, 32]],
      [15, [255, 80, 41]],
      [16, [255, 68, 51]],
      [17, [253, 57, 62]],
      [18, [251, 47, 73]],
      [19, [247, 37, 85]],
      [20, [241, 29, 97]]
    ])
    return {
      mapColor: mC,
      severityQuantiles: [],
      calculated: false
    }
  },

  computed: {
    getEventStyle () {
      const color = this.numToRgb(this.tdData.severityAvg)
      return `width: 10px;
              height: 100%;
              color: rgba(${color});
              background-color: rgba(${color});`
    }
  },

  methods: {
    severityFunctionGetter (formula) {
      if (formula === 'FORMULA1') {
        return function (metric, { threshold, exponent }) {
          const val = metric - threshold
          if (val > 0) {
            const ret = (Math.pow(val, exponent))
            if (ret > 1) {
              return 1
            }
            return ret
          }
          return 0
        }
      } else if (formula === 'FORMULA2') {
        return function (metric, { threshold, exponent }) {
          if (threshold === 0) {
            return -1
          }
          const val = metric / threshold - 1
          if (val > 0) {
            const ret = Math.pow(val, exponent)
            if (ret > 1) {
              return 1
            }
            return ret
          }
          return 0
        }
      } else if (formula === 'FORMULA3') {
        return function (metric, { threshold, exponent }) {
          if (threshold === 0) {
            return -1
          }
          const val = metric / threshold
          if (val > 0) {
            const ret = 1 - Math.pow(val, exponent)
            if (ret > 1) {
              return 1
            }
            if (ret < 0) {
              return 0
            }
            return ret
          }
          return 0
        }
      } else if (formula === 'MEMORY_FORMULA') {
        return function (metric, { threshold, propertyvalMax }) {
          const denominator = propertyvalMax - threshold
          let severity = -1
          if (denominator !== 0) {
            severity = (metric - threshold) / denominator
            if (severity > 1) {
              severity = 1
            } else if (severity < 0) {
              severity = 0
            }
          }
          return severity
        }
      }
    },

    calculateQuantiles (data) {
      // cumulative distribution function (cfd)
      const cdf = [...data]
      const numberQuantiles = 10
      cdf.sort()
      let myMin = 0
      let myMax = 0
      if (cdf.length !== 0) {
        myMin = cdf[0]
        myMax = cdf[cdf.length - 1]
      }

      const elementNumber = cdf.length
      const factor = elementNumber / numberQuantiles
      const quantiles = [myMin]
      for (let i = 1; i < numberQuantiles; i++) {
        if (elementNumber > 1) {
          const idx = Math.floor(i * factor)
          const rest = (i * factor) - idx
          if (idx === 0) {
            quantiles.push(cdf[0])
          } else {
            quantiles.push(cdf[idx - 1] + rest * (cdf[idx] - cdf[idx - 1]))
          }
        } else {
          quantiles.push(myMin)
        }
      }
      quantiles.push(myMax)
      return quantiles
    },

    getDataBetweenQuantiles (i, generatedData, supQu,
      infQu, obsBetweenQuantile) {
      let iterate = 0
      if (i === 1) {
        // insert observProQuantile -2 because minimum (infQu) is already
        // inserted, and we also insert the supQu
        generatedData.push(infQu)
        iterate = obsBetweenQuantile - 2
      } else {
        iterate = obsBetweenQuantile - 1
      }

      for (let j = 1; j <= iterate; j++) {
        const newval = (supQu - infQu) * Math.random() + infQu
        generatedData.push(newval)
      }
      generatedData.push(supQu)
      return generatedData
    },

    randomGenFromQuantiles (numObs, quantiles) {
      const obsBetweenQu = numObs / (quantiles.length - 1)
      const numQuantiles = 10
      let infQu = quantiles[0]
      let generatedData = [infQu] // Array with first element quantile0
      if (numObs < numQuantiles) {
        // case where number of observations is small
        // and the quantiles have repeated info
        for (let i = 2; i < numObs; ++i) {
          const idx = Math.floor(numQuantiles / numObs) * i
          const rest = i * (numQuantiles / numObs) - idx
          if (idx + 1 <= numQuantiles) {
            generatedData.push(quantiles[idx] + (quantiles[idx + 1] - quantiles[idx]) * rest)
          }
        }
        if (generatedData.length < numObs) { // max (i=numObs)
          generatedData.push(quantiles[quantiles.length - 1]) // max
        }
        return generatedData
      } else {
        for (let i = 1; i < quantiles.length; i++) {
          const supQu = quantiles[i]
          generatedData = this.getDataBetweenQuantiles(
            i, generatedData, supQu, infQu, obsBetweenQu)
          infQu = supQu
        }
        return generatedData
      }
    },

    calculateSeverityQuantiles (data, { formula, exponent, threshold, propertyvalMax }) {
      const severityFunct = this.severityFunctionGetter(formula)
      const severities = data.map(metric => {
        return severityFunct(metric, { exponent, threshold, propertyvalMax })
      })
      return this.calculateQuantiles(severities)
    },

    onShow () {
      if (!this.calculated) {
        const genData = this.randomGenFromQuantiles(this.tdData.numObs, this.tdData.propQuantiles)
        this.severityQuantiles = this.calculateSeverityQuantiles(genData, this.property)
        this.calculated = true
      }
    },

    numToRgb (val) {
      const arr = this.mapColor.get(Math.round(val * 20))
      if (arr === undefined) {
        return [211, 211, 211]
      }
      return arr
    },
    getColStyle (value) {
      const color = this.numToRgb(value)
      return `background-color: rgba(${color});
              font-size: small;`
    },
    goToPerformance () {
      const self = this
      this.$router.push({
        name: 'Performance',
        params: { propId: self.property.property_type_id }
      })
    },
    getSeverityPct (val) {
      return `${(val).toFixed(2)}`
    },
    getQName (index) {
      return `${index * 10}`
    },
    getNiceTS (timestamp) {
      const tsnice = new Date(timestamp)
      return tsnice.toString().split(' ').slice(0, 5).join(' ')
    }
  }
}
</script>

<style scoped>
.minw {
  max-width: 200;
}

.smfont {
  font-size: small;
}
</style>
