<template>
  <div>
  <b-container fluid>
    <b-row><br></b-row>
    <b-row><b-col><h3>Detailed Accounting</h3></b-col></b-row>
    <!-- Table Controls -->
    <b-row v-if="hasItems">
      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="table.currentPage"
          :total-rows="table.totalRows"
          :per-page="table.perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row><b-col>
      <!-- Accounting Information Table -->
      <b-table
        class="accounting-table text-nowrap"
        striped
        hover
        bordered
        outlined
        responsive
        selectable
        selected-variant="primary"
        sticky-header="90vh"
        small
        :items="table.items"
        :fields="table.fields"
        :per-page="table.perPage"
        :currentPage="table.currentPage"
        @row-selected="onRowSelected"
      >
        <template #cell(action)="data">
          <div>
            <b-button
              class="m-0"
              style="width: 60px; z-index: 5;"
              size="sm"
              @click="goToTimeline(data.item)"
              :disabled="data.item.perfdata_available === 0"
            ><b-icon icon="hand-index" v-if="data.item.perfdata_available === 1" class="mr-1"/><b-icon icon="x-circle" v-else/></b-button>
          </div>
        </template>
        <template #cell(job_id_string)="data">
          {{ shortJobName(data.item.job_id_string) }}
        </template>
        <template #cell(batch_domain)="data">
          {{ getBatchName(data.item.batch_domain) }}
        </template>
      </b-table>
    </b-col></b-row>
    <b-row class="justify-content-md-center mt-3">
     <h3>Create a budget overview</h3>
    </b-row>
    <b-row align-v="center">
      <b-col cols="5">
        <span style="text-align:left; font-weight:bold;">Summary options:</span>
        <b-card no-body style="max-width: 45rem;" class="ml-2">
          <b-tabs card content-class="mt-3">
            <b-tab title="Interval">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="timeSummary.selected"
                  :options="timeSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="time-radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <b-icon icon="exclamation-triangle-fill" variant="warning"/> Note: uses job's end time to summarize
            </b-tab>
            <b-tab title="Project and user">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-2"
                  v-model="groupingSummary.selected"
                  :options="groupingSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="grouping-radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-tab>
            <b-tab title="Accountable">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-3"
                  v-model="accountableSummary.selected"
                  :options="accountableSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="accountable-radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <b-icon icon="exclamation-triangle-fill" variant="warning"/> Jobs which are marked not accountable are not substracted from your budget. Typically, these are jobs with node failures.
            </b-tab>
            <b-tab title="Job state">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-4"
                  v-model="stateSummary.selected"
                  :options="stateSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="state-radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-tab>
            <b-tab title="Job size">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-5"
                  v-model="nnodesSummary.selected"
                  :options="nnodesSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="size-radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-tab>
            <b-tab title="Partition">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-6"
                  v-model="partitionSummary.selected"
                  :options="partitionSummary.options"
                  :aria-describedby="ariaDescribedby"
                  name="partition-radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col>
        <b-overlay :show="calcSummaryBusy" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
          <b-button @click="calculateSummary()" class="mr-3"><b-icon icon="file-earmark-spreadsheet-fill"/> Calculate summary</b-button>
        </b-overlay>
        <span class="h4"><b-icon v-if="summaryData.length !== 0" icon="arrow-down" animation="cylon-vertical"/></span>
      </b-col>
      <b-col>
         <b-button @click="copy()" v-if="summaryData.length !== 0"><b-icon icon="clipboard"/> Copy in CSV format</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
      <b-table id="summary-table"
        v-if="summaryData.length !== 0" :items="summaryData" :fields="fields"
        striped
        hovered
        small
        bordered
        outlined
        responsive
        sticky-header="45vh"
        :tbody-transition-props="transProps"
        no-footer-sorting
        class="mt-3">
        <template #cell(Core_Hours)="data">
          {{ data.item.Core_Hours.toFixed(2) }}
        </template>
        <template #cell(GPU_Hours)="data">
          {{ data.item.GPU_Hours.toFixed(2) }}
        </template>
        <template v-slot:custom-foot>
          <tr variant="warning">
            <td :colspan="footer.colspan" >Total Sum:</td>
            <td>{{ getPUTotal() }}</td>
            <td>{{ footer['Number of Jobs'] }} </td>
          </tr>
        </template>
      </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <br>
      <strong>* weighted with the budgeting factor which is usually equal to 1. Refer to the column "Budgeting Factor" in order to see the actual value.</strong>
      </b-col>
    </b-row>
  </b-container>
      <!-- Spinner -->
      <b-modal
    ref="loadingModal"
    no-close-on-esc
    no-close-on-backdrop
    centered
    hide-header-close>
    <template #modal-title>
      <span class="bold"><b-icon icon="server" class="mr-2"/>
      Loading accounting data ... </span>
    </template>
    <div class="d-block text-center">
      <b-spinner variant="secondary" class="m-2" style="width: 3rem; height: 3rem;" ></b-spinner>
    </div>
    <!-- Action buttons on the modal footer -->
    <template v-slot:modal-footer>
      <b-button variant="outline-danger" @click="cancel">Cancel</b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>
import errorcommon from '@/common/error'
import batchids from '@/common/batchids'
import acctable from '@/common/acctable'

export default {
  name: 'job-acc-only',

  computed: {
    hasItems () {
      return this.table.items.length > 0
    }
  },

  data () {
    return {
      timeSummary: {
        options: ['All', 'Weekly', 'Monthly'],
        selected: 'All'
      },
      groupingSummary: {
        options: ['All', 'Per Project', 'Per project and user', 'Per user'],
        selected: 'All'
      },
      accountableSummary: {
        options: ['All', 'Only Accountable'],
        selected: 'All'
      },
      stateSummary: {
        options: ['All', 'Per state'],
        selected: 'All'
      },
      nnodesSummary: {
        options: ['All', 'Per job size'],
        selected: 'All'
      },
      partitionSummary: {
        options: ['All', 'Per partition'],
        selected: 'All'
      },
      summaryData: [],
      fields: [],
      footer: {},
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      calcSummaryBusy: false,
      isGPU: false,
      table: {
        items: [], // initialized on component mount
        fields: [], // initialized through items
        perPage: 12, // number of rows to display by default
        currentPage: 1, // start at first page
        totalRows: -1 // initialized on component mount
      },
      selected: [],
      accounting: []
    }
  },
  async mounted () {
    const self = this
    let batchdomain = self.$route.query.batchdomain
    if (!batchdomain) {
      batchdomain = batchids.getDefaultCluster()
    }
    self.isGPU = batchids.getPuType(batchdomain) === 'GPU'
    self.accounting = this.$store.getters.getFilteredSearchAccounting(self.$route.query)
    if (!self.accounting) {
      try {
        self.$refs.loadingModal.show()
        await self.$store.dispatch('fetchFilteredAccounting', self.$route.query).catch(error => { errorcommon.handleError(error, self.$router) })
      } catch (error) {
        errorcommon.handleError(error, self.$router)
      } finally {
        self.$refs.loadingModal.hide()
      }
    }
    self.accounting = this.$store.getters.getFilteredSearchAccounting(self.$route.query)
    if (self.accounting) {
      this.initTable(self.accounting)
    }
  },

  methods: {
    async calculateSummary () {
      const self = this
      self.calcSummaryBusy = true
      if (!self.accounting[0]) {
        return
      }
      let jobsizelarge = false
      if (this.nnodesSummary.selected === 'Per job size') {
        jobsizelarge = this.isJobSizeListLarge(self.accounting)
      }
      const categoryMap = new Map()
      this.summaryData = [] // purge previous content and initialize
      this.fields = []
      if (self.isGPU) {
        this.footer = { GPU_Hours: 0, 'Number of Jobs': 0 }
      } else {
        this.footer = { Core_Hours: 0, 'Number of Jobs': 0 }
      }
      self.accounting.forEach(record => {
        if (this.accountableSummary.selected === 'Only Accountable' && record.accountable === 0) {
          return // inside a forEach equivalent to "continue"
        }
        const key = self.getKey(record, jobsizelarge)
        const accum = categoryMap.get(key)
        const numPU = self.isGPU ? record.gpus : record.cores
        const hours = (record.wallclock / 3600) * numPU * record.budgeting_factor
        if (self.isGPU) {
          self.footer.GPU_Hours += hours
        } else {
          self.footer.Core_Hours += hours
        }
        self.footer['Number of Jobs'] += 1
        if (accum) {
          accum.hours += hours
          accum.jobs += 1
        } else {
          categoryMap.set(key, { hours: hours, jobs: 1 })
        }
      })
      const catg = this.getCategories()
      this.setFields(catg)
      categoryMap.forEach((value, key, map) => {
        // map is not needed and thus ignored
        const splitCat = key.split(' ')
        const tmpObj = {}
        splitCat.forEach((catVal, index) => {
          tmpObj[catg[index]] = catVal
        })
        if (self.isGPU) {
          tmpObj.GPU_Hours = value.hours
        } else {
          tmpObj.Core_Hours = value.hours
        }
        tmpObj['Number of Jobs'] = value.jobs
        this.summaryData.push(tmpObj)
      })
      self.footer.colspan = catg.length
      self.calcSummaryBusy = false
    },

    setFields (categories) {
      categories.forEach(category => {
        this.fields.push({ key: category, sortable: true })
      })
      if (this.isGPU) {
        this.fields.push({ key: 'GPU_Hours', sortable: true, label: 'GPU Hours*' })
      } else {
        this.fields.push({ key: 'Core_Hours', sortable: true, label: 'Core Hours*' })
      }
      this.fields.push({ key: 'Number of Jobs', sortable: true })
    },

    getPUTotal () {
      if (this.isGPU) {
        return this.footer.GPU_Hours.toFixed(2)
      }
      return this.footer.Core_Hours.toFixed(2)
    },

    isTotal () {
      const all = 'All'
      return this.timeSummary.selected === all && this.groupingSummary.selected === all && this.stateSummary.selected === all &&
        this.partitionSummary.selected === all && this.nnodesSummary.selected === all
    },

    getKey (record, jsizel) {
      if (this.isTotal()) {
        return 'Total'
      }
      let key = ''
      switch (this.groupingSummary.selected) {
        case 'Per Project':
          key = record.project
          break
        case 'Per project and user':
          key = record.project + ' ' + record.user
          break
        case 'Per user':
          key = record.user
          break
      }
      if (this.stateSummary.selected === 'Per state') {
        key === '' ? key = record.state : key += ' ' + record.state
      }
      const getWeek = function (date) {
        const onejan = new Date(date.getFullYear(), 0, 1)
        return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7) - 1
      }
      const date = new Date(record.end_time)
      switch (this.timeSummary.selected) {
        case 'Monthly':
          if (key !== '') {
            key += ' '
          }
          key += date.getFullYear() + '-' + (date.getMonth() + 1)
          break
        case 'Weekly':
          if (key !== '') {
            key += ' '
          }
          key += date.getFullYear() + '-' + (date.getMonth() + 1) + '_Week_' + getWeek(date)
          break
      }
      if (this.nnodesSummary.selected === 'Per job size') {
        if (key !== '') key += ' '
        if (jsizel) {
          let k = 2
          for (; k <= record.nodes; k <<= 1);
          const prevRange = k >> 1
          key += (prevRange === 1 ? '1' : prevRange + 1).toString() + '-' + k.toString()
        } else {
          key += record.nodes.toString()
        }
      }
      if (this.partitionSummary.selected === 'Per partition') {
        key === '' ? key = record.job_class : key += ' ' + record.job_class
      }
      return key
    },

    isJobSizeListLarge (records) {
      const jsizes = new Set()
      const BreakException = {}
      try {
        records.forEach(record => {
          jsizes.add(record.nodes)
          if (jsizes.size > 40) throw BreakException
        })
      } catch (e) {
        if (e === BreakException) return true
      }
      return false
    },

    getCategories () {
      if (this.isTotal()) {
        return ['Total']
      }
      const catg = []
      switch (this.groupingSummary.selected) {
        case 'Per Project':
          catg.push('Project')
          break
        case 'Per project and user':
          catg.push('Project')
          catg.push('User')
          break
        case 'Per user':
          catg.push('User')
          break
      }
      if (this.stateSummary.selected === 'Per state') {
        catg.push('State')
      }
      switch (this.timeSummary.selected) {
        case 'Monthly':
          catg.push('Month')
          break
        case 'Weekly':
          catg.push('Calendar Week')
          break
      }
      if (this.nnodesSummary.selected === 'Per job size') {
        catg.push('Nodes')
      }
      if (this.partitionSummary.selected === 'Per partition') {
        catg.push('Partition')
      }

      return catg
    },

    async copy () {
      const self = this
      if (self.summaryData.length > 0) {
        const catg = this.getCategories()
        let copytext = catg.join(';')
        if (self.isGPU) {
          copytext += ';GPU Hours;Number Of Jobs\n'
        } else {
          copytext += ';Core Hours;Number Of Jobs\n'
        }
        this.summaryData.forEach(row => {
          catg.forEach(category => {
            if (row[category] === undefined) {
              copytext += '-;'
            } else {
              copytext += row[category] + ';'
            }
          })
          let puText
          if (self.isGPU) {
            puText = row.GPU_Hours
          } else {
            puText = row.Core_Hours
          }
          copytext += puText + ';' + row['Number of Jobs'] + '\n'
        })
        await navigator.clipboard.writeText(copytext)
      }
    },

    initTable (items) {
      if (this.isGPU) {
        this.table.items = acctable.addGPUHours(items)
      } else {
        this.table.items = acctable.addCoreHours(items)
      }
      this.table.totalRows = this.table.items.length
      // generate table column header fields from first row
      this.table.fields = acctable.init_acc_table(this.table.items)
    },
    shortJobName (name) {
      return name.length > 48 ? name.substring(0, 45) + '...' : name
    },
    getBatchName (id) {
      return batchids.getBatchname(id)
    },
    onRowSelected (items) {
      const self = this
      self.selected = [] // initialize
      items.forEach(record => {
        self.selected.push(record)
      })
    },
    cancel () {
      this.$refs.loadingModal.hide()
      this.$store.commit('cancel')
    }
  }
}
</script>

<style scoped>
.ovf {
  overflow-x: scroll;
}
table#summary-table .flip-list-move {
  transition: transform 1s;
}
.accounting-table tr td:first-child {
  z-index: 4 !important;
}

.accounting-table thead th:first-child {
  z-index: 10 !important;
}
</style>
