import axios from 'axios'
import storeFuncts from './storeFuncts'

export default {
  calculatePropertyJobTable ({ commit, getters }, { jobId }) {
    return new Promise((resolve, reject) => {
      let jobPropertyTable = []
      const jobObj = getters.getJobPerfData(jobId)
      if (jobObj && jobObj !== '') {
        jobPropertyTable = storeFuncts.jobDataToTable(jobObj)
        commit('setPropertyJobTable', { jobId, jobPropertyTable })
        resolve(jobPropertyTable)
      } else {
        reject(new Error(`Sorry, data is not coherent, we don't have performance data for jobId=${jobId}`))
      }
    })
  },

  fetchProperties ({ commit, getters }) {
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/property_types/`)
          .then(response => {
            const properties = response.data
            properties.forEach(prop => {
              // renaming for convenience...
              prop.propertyvalMax = prop.propertyval_max
            })
            if (getters.hasPropertyTree && !getters.arePropertiesSorted) {
              storeFuncts.sortProperties(properties, getters.propertyTree)
              commit('setPropertiesSorted')
            }

            commit('setProperties', { properties })
            resolve(properties)
          })
      })
    })
  },

  fetchPropertyTree ({ commit, getters }) {
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/parent_child/`)
          .then(response => {
            const propertyTree = response.data
            if (getters.hasProperties && !getters.arePropertiesSorted) {
              storeFuncts.sortProperties(getters.properties, propertyTree)
              commit('setPropertiesSorted')
            }
            commit('setPropertyTree', { propertyTree })
            resolve(response.data)
          })
      })
    })
  },

  fetchJobs ({ commit, getters }) {
    commit('resetCancel')
    const jobIds = getters.perfJobs
    return Promise.all(jobIds.map(jobId => {
      return new Promise((resolve, reject) => {
        if (!getters.isCancel) {
          const perfdata = getters.getJobPerfData(jobId)
          if (perfdata) {
            resolve(perfdata)
          } else {
            axios.get(`${process.env.VUE_APP_API_BASE_URL}/performance_data/${jobId}/`)
              .then(response => {
                const job = response.data
                commit('setJob', { jobId, job })
                resolve(job)
              })
          }
        }
      })
    }))
  },

  fetchStoreJobs ({ commit, getters }) {
    commit('resetCancel')
    return Promise.all(getters.getStorePerfJobs.map(jobId => {
      return new Promise((resolve, reject) => {
        if (!getters.isCancel) {
          const perfdata = getters.getJobPerfData(jobId)
          if (perfdata) {
            resolve(perfdata)
          } else {
            axios.get(`${process.env.VUE_APP_API_BASE_URL}/performance_data/${jobId}/`)
              .then(response => {
                const job = response.data
                commit('setJob', { jobId, job })
                resolve(job)
              })
          }
        }
      })
    }))
  },

  fetchAccounting ({ commit }, { jobIds }) {
    const jobGroups = []
    const numInGroup = 10
    for (let i = 0; i < jobIds.length; i += numInGroup) {
      jobGroups.push(jobIds.slice(i, i + numInGroup).join(','))
    }
    if (jobIds.length === 0 || jobGroups.length === 0) {
      return new Promise((resolve, reject) => {
        reject(new Error(`Empty query, jobIds= ${jobIds}`))
      })
    }
    const config = { headers: window.APIClient.getAuthHeader() }
    return Promise.all(jobGroups.map(jobIds => {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/accounting/?job_id_string=${jobIds}`, config)
          .then(response => {
            response.data.forEach(item => {
              const jobId = item.job_id_string
              if (item.perfdata_available === 1) {
                // if (jobId === null || jobId === undefined) console.log('fetchAccounting has null jobid')
                commit('storeJobWithPerf', { jobId })
              }
              commit('storeJobId', { jobId })
              commit('setAccounting', { key: jobId, accountings: item })
            })
            resolve(response.data)
          })
      })
    }))
  },

  fetchFilteredAccounting ({ commit }, formdata) {
    const urlQuery = storeFuncts.convertQueryToURL(formdata)
    commit('setSearchURL', urlQuery)
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        const config = { headers: window.APIClient.getAuthHeader() }
        axios.get(urlQuery, config)
          .then(response => {
            const jobidsPerf = []
            const accountings = response.data.results
            for (let i = 0; i < accountings.length; ++i) {
              const jobId = accountings[i].job_id_string
              const accounting = accountings[i]
              if (!formdata.accOnly && accounting.perfdata_available === 1) {
                // if (jobId === null || jobId === undefined) console.log('fetchFilteredAccounting has null/undefined jobid')
                commit('storeJobWithPerf', { jobId })
                jobidsPerf.push(jobId)
              }
              commit('storeJobId', { jobId })
            }
            const key = storeFuncts.filteredSearchMakeKey(formdata)
            commit('setAccounting', { key, accountings })
            response.data.jobidsPerf = jobidsPerf
            resolve(response.data)
          })
      })
    })
  },

  countJobsWithPerfdata ({ commit }, formdata) {
    const formdataClone = { ...formdata }
    // change limit to 1
    formdataClone.limit = 1
    formdataClone.perfdataAvailable = 1
    const urlQuery = storeFuncts.convertQueryToURL(formdataClone)
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        const config = { headers: window.APIClient.getAuthHeader() }
        axios.get(urlQuery, config)
          .then(response => {
            resolve(response.data.count)
          })
      })
    })
  },

  countJobs ({ commit }, formdata) {
    const formdataClone = { ...formdata }
    // change limit to 1
    formdataClone.limit = 1
    const urlQuery = storeFuncts.convertQueryToURL(formdataClone)
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        const config = { headers: window.APIClient.getAuthHeader() }
        axios.get(urlQuery, config)
          .then(response => {
            resolve(response.data.count)
          })
      })
    })
  },

  fetchAccountingFromURL ({ commit, getters }) {
    const url = getters.getSearchURL
    const jobIds = getters.jobs
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        const config = { headers: window.APIClient.getAuthHeader() }
        axios.get(url, config)
          .then(response => {
            const accountings = response.data.results
            for (let i = 0; i < accountings.length; ++i) {
              const jobId = accountings[i].job_id_string
              const found = jobIds.find(jid => { return jobId === jid })
              if (found !== undefined) {
                const accounting = accountings[i]
                // why not commit('storeJobId', { jobId }) ??
                commit('setAccounting', { key: jobId, accountings: accounting })
              }
            }
            resolve(response.data.results)
          })
      })
    })
  },

  fetchAccountingFromJob ({ commit }, { jobId }) {
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        const config = { headers: window.APIClient.getAuthHeader() }
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/accounting/${jobId}/`, config)
          .then(response => {
            // console.error('endpoint should return one object?')
            const accounting = response.data
            commit('storeJobId', { jobId })
            commit('setAccounting', { key: jobId, accountings: accounting })
            resolve(response.data)
          })
      })
    })
  },

  fetchPerformanceDataFromJob ({ commit, getters }, { jobId }) {
    // if (jobId === null || jobId === undefined) console.log('fetchPerformanceDataFromJob has null/undefined jobid')
    commit('resetCancel')
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        if (!getters.isCancel) {
          axios.get(`${process.env.VUE_APP_API_BASE_URL}/performance_data/${jobId}/`)
            .then(response => {
              const job = response.data
              commit('storeJobWithPerf', { jobId })
              commit('setJob', { jobId, job })
              resolve(job)
            })
        }
      })
    })
  },

  fetchMonthlySummaryCPU ({ commit }, formdata) {
    const baseurl = `${process.env.VUE_APP_API_BASE_URL}/accsum/`
    const urlQuery = storeFuncts.attachParamsToURL(baseurl, formdata)
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        const config = { headers: window.APIClient.getAuthHeader() }
        axios.get(urlQuery, config)
          .then(response => {
            const sumlist = response.data
            const key = storeFuncts.monthlySummaryMakeKey(formdata)
            commit('setMonthlySummary', { key, sumlist })
            resolve(response.data.count)
          })
      })
    })
  },

  fetchMonthlySummaryGPU ({ commit }, formdata) {
    const baseurl = `${process.env.VUE_APP_API_BASE_URL}/gpuaccsum/`
    const urlQuery = storeFuncts.attachParamsToURL(baseurl, formdata)
    return window.APIClient.refresh().then(resp => {
      return new Promise((resolve, reject) => {
        const config = { headers: window.APIClient.getAuthHeader() }
        axios.get(urlQuery, config)
          .then(response => {
            const sumlist = response.data
            const key = storeFuncts.monthlySummaryMakeKey(formdata)
            commit('setMonthlySummary', { key, sumlist })
            resolve(response.data.count)
          })
      })
    })
  },

  fetchJobScript ({ commit }, { jobId }) {
    return window.APIClient.refresh().then(resp => {
      const config = { headers: window.APIClient.getAuthHeader() }
      return axios.get(`${process.env.VUE_APP_API_BASE_URL}/jobscript/${jobId}/`, config)
        .then(response => {
          const script = response.data.job_script
          commit('setJobScript', { jobId, script })
        })
    })
  },

  fetchModules ({ commit }, { jobId, batchdomain }) {
    return window.APIClient.refresh().then(resp => {
      const config = { headers: window.APIClient.getAuthHeader() }
      return axios.get(`${process.env.VUE_APP_API_BASE_URL}/modules/${jobId}/${batchdomain}/`, config)
        .then(response => {
          const script = response.data
          commit('setModules', { jobId, script })
        })
    })
  },

  refreshAPI () {
    return window.APIClient.refresh()
  }
}
