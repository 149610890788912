<template>
  <div class="table-cell" :style="backgroundColor">
    <p>
    {{ showPercentage(this.$props.severity) }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'table-cell',

  props: {
    severity: Number
  },

  computed: {
    backgroundColor () {
      const color = this.numToRgb(this.$props.severity)
      return `background-color: rgba(${color})`
    }
  },

  methods: {
    numToRgb (val) {
      // https://stackoverflow.com/questions/57400584/
      // how-to-map-a-range-of-numbers-to-rgb-in-python
      // originally Math.sin(0.024 * i + ..) but this range
      // was too large
      if (val !== '' && val >= 0 && val <= 1) {
        const i = 0.008 * val * 255
        const r = Math.round(Math.sin(i) * 127 + 128)
        const g = Math.round(Math.sin(i + 2) * 127 + 128)
        const b = Math.round(Math.sin(i + 4) * 127 + 128)
        return [r, g, b]
      }
      return [211, 211, 211]
    },

    showPercentage (severity) {
      if (severity !== -1) {
        return `${severity.toFixed(2)}`
      }
      return 'N/A'
    }
  }
}
</script>

<style>
.table-cell {
  width: 100%;
  height: 30px;
}
</style>
