<template>
  <div>
    <b-container fluid>
      <accounting-table
        ref="accountingTbl"
        @timeline="reEmitTL"
        @rowSelected="selectInAverageTable"></accounting-table>
      <average-table
        ref="avgTable"
        @fetched="reEmit"
        @timeline="reEmitTL"
        @rowSelected="selectInAccounting"
        ></average-table>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'job',
  methods: {
    reEmit (msg) {
      this.$emit('fetched', msg)
    },
    reEmitTL (msg) {
      this.$emit('timeline', msg)
    },
    selectInAverageTable (msg) {
      this.$refs.avgTable.selectRow(msg)
    },
    selectInAccounting (msg) {
      this.$refs.accountingTbl.selectRow(msg)
    }
  }
}
</script>
