<template>
  <div class="perfjobinfo">
    <div v-if="jobAccounting">
     <b-button :id="getId" class="m-md-2" size="sm">Performance Info</b-button>
     <b-popover :target="getId" triggers="click" placement="right">
        <strong>MaxRSS: </strong> {{ jobAccounting.maxrss }} KB <br>
        <b-icon icon="exclamation-triangle-fill" variant="warning"/> Note: Maximum Resident Set Size may be undercounted if your job processes were killed.<br>
        <strong>User Time:</strong> {{ jobAccounting.user_time }} ms <br>
        <strong>System Time:</strong> {{ jobAccounting.system_time }} ms <br>
        <strong>Average Frequency: </strong> {{ jobAccounting.frequency }} MHz <br>
        <strong>Average Power:</strong> {{ getAveragePower }} <br>
        <strong>Energy Consumption:</strong> {{ getEnergyConsumption }}
     </b-popover>
    </div>
    <div v-else>
    Error: No job data
    </div>
  </div>
</template>

<script>
import errorcommon from '@/common/error.js'

export default {
  name: 'PerfJobInfo',
  data () {
    return {
      jobId: undefined,
      jobAccounting: undefined
    }
  },
  async mounted () {
    const self = this
    const jobId = self.jobId = self.$router.currentRoute.params.jobId
    self.jobAccounting = this.$store.getters.getJobAccounting(this.jobId)
    if (!self.jobAccounting) {
      self.$store.dispatch('fetchAccountingFromJob', { jobId })
        .then(resolve => { self.jobAccounting = this.$store.getters.getJobAccounting(this.jobId) })
        .catch(error => { errorcommon.handleError(error, self.$router) })
    }
  },
  computed: {
    getId () {
      return `${this.jobId}-perf`
    },
    getAveragePower () {
      if (this.jobAccounting && this.jobAccounting.avg_power !== null) {
        return `${this.jobAccounting.avg_power} Watts`
      }
      return 'N/A'
    },
    getEnergyConsumption () {
      if (this.jobAccounting && this.jobAccounting.energy_consumption !== null) {
        return `${this.jobAccounting.energy_consumption} Joules`
      }
      return 'N/A'
    }
  }
}
</script>

<style scoped>
.perfjobinfo {
  max-width: 430px;
}
</style>
