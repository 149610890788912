import batchids from '@/common/batchids'

const LOCAL_STORAGE = Object.freeze({
  JOBIDS: 'jobIds',
  SEARCHURL: 'searchURL',
  PERF_JOBS: 'perfjobs'
})

export default {
  LOCAL_STORAGE,

  timeToTimestamp (date, time) {
    if (date !== '' && date) {
      if (time !== '' && time) {
        return date.toString() + '%20' + time.toString()
      }
      return date.toString() + '%2000:00:00'
    }
    return 'none'
  },

  commaSplit (mystr) {
    return mystr !== '' && mystr ? mystr.split(' ').join('') : 'none'
  },

  convertWallClockToSeconds (mystr) {
    if (mystr === '' || !mystr) return 'none'
    const maxTime = 172800
    const hMS = mystr.split(':')
    let mytime = 0
    if (hMS.length > 3 || hMS.length === 0) return 'none' // We ignore the input
    const hourMinSec = []
    for (let i = 0; i < hMS.length; i++) {
      const myint = parseInt(hMS[i])
      if (!isNaN(myint) && myint >= 0) {
        hourMinSec.push(myint)
      } else {
        return 'none'
      }
    }
    if (hourMinSec.length === 1) {
      mytime = hourMinSec[0] // Assume ss
    } else if (hourMinSec.length === 2) {
      mytime = hourMinSec[0] * 60 + hourMinSec[1] // Assume mm:ss
    } else if (hourMinSec.length === 3) {
      mytime = hourMinSec[0] * 60 * 60 + hourMinSec[1] * 60 + hourMinSec[2] // Assume HH:mm:ss
    }
    return mytime < maxTime ? mytime : 'none'
  },

  convertQueryToURL ({
    batchdomain, startDate, startTime, endDate, endTime, nodesMin, nodesMax,
    state, wallclockMin, wallclockMax, limit, projectId, userId, stateAll, jobClass, jobClassAll, perfdataAvailable
  }) {
    const urlQuery = `${process.env.VUE_APP_API_BASE_URL}/accounting/?limit=${limit}`
    return this.attachParamsToURL(urlQuery, {
      batchdomain,
      startDate,
      startTime,
      endDate,
      endTime,
      nodesMin,
      nodesMax,
      state,
      wallclockMin,
      wallclockMax,
      limit,
      projectId,
      userId,
      stateAll,
      jobClass,
      jobClassAll,
      perfdataAvailable
    })
  },

  attachParamsToURL (baseurl, {
    batchdomain, startDate, startTime, endDate, endTime, nodesMin, nodesMax,
    state, wallclockMin, wallclockMax, projectId, userId, stateAll, jobClass, jobClassAll, perfdataAvailable
  }) {
    const starttime = this.timeToTimestamp(startDate, startTime)
    const endtime = this.timeToTimestamp(endDate, endTime)
    let wallClockMin
    if (wallclockMin) {
      wallClockMin = this.convertWallClockToSeconds(wallclockMin) // conversion mins to seconds
    }
    let wallClockMax
    if (wallclockMax) {
      wallClockMax = this.convertWallClockToSeconds(wallclockMax) // conversion mins to seconds
    }
    const projectids = this.commaSplit(projectId)
    const userids = this.commaSplit(userId)
    const hasquest = baseurl.includes('?')
    if (batchdomain === 'Both') {
      const all = batchids.getAllBatchids()
      baseurl += `&batch_domain=${all}`
    } else {
      if (hasquest) baseurl += `&batch_domain=${batchdomain}`
      else baseurl += `?batch_domain=${batchdomain}`
    }
    if (userids !== 'none' || !userids) {
      baseurl += '&user=' + userids
    }
    if (projectids !== 'none') {
      baseurl += '&project=' + projectids
    }
    if (!jobClassAll && jobClass) {
      baseurl += '&job_class=' + jobClass
    }
    if (nodesMin > 0) {
      baseurl += '&nodes_min=' + nodesMin
    }
    if (nodesMax < 6336) {
      baseurl += '&nodes_max=' + nodesMax
    }
    if (!stateAll && state) {
      baseurl += '&state=' + state
    }
    if (wallClockMin !== 'none' && wallClockMin) {
      baseurl += '&wallclock_min=' + wallClockMin
    }
    if (wallClockMax !== 'none' && wallClockMax) {
      baseurl += '&wallclock_max=' + wallClockMax
    }
    if (starttime === 'none' && endtime === 'none') {
      baseurl += '&end_time_isnull=False'
    } else {
      if (starttime !== 'none' && starttime) {
        baseurl += '&end_time_start=' + starttime
      }
      if (endtime !== 'none' && endtime) {
        baseurl += '&end_time_end=' + endtime
      }
    }
    if (perfdataAvailable === 1) {
      baseurl += '&perfdataavailable=1'
    }
    return baseurl
  },

  binaryInsertion (arr, element) {
    // adapted from:
    // https://stackoverflow.com/
    // questions/60702410/how-to-insert-value-in-sorted-array-and-keep-array-sorted
    if (arr.length === 0) {
      arr.push(element)
      return
    } else if (arr.length === 1) {
      element.timestamp.localeCompare(arr[0].timestamp) >= 0
        ? arr.push(element)
        : arr.unshift(element)
      return
    }
    let lBound = 0
    let uBound = arr.length - 1
    while (uBound - lBound !== 1) {
      // we look for the middle point
      const midPoint = Math.floor((uBound - lBound) / 2) + lBound
      // depending on the value in the middle, we repeat the operation only on one slice of the array, halving it each time
      element.timestamp.localeCompare(arr[midPoint].timestamp) < 0
        ? uBound = midPoint
        : lBound = midPoint
    }
    if (element.timestamp.localeCompare(arr[lBound].timestamp) < 0) arr.splice(lBound, 0, element)
    else if (element.timestamp.localeCompare(arr[uBound].timestamp) > 0) arr.splice(uBound + 1, 0, element)
    else arr.splice(uBound, 0, element)
  },

  sortProperties (properties, propTree) {
    properties.sort(function (a, b) {
      if (a.rank === b.rank) {
        for (let i = 0; i < propTree.length; i++) {
          const paren = propTree[i].parent_property_type_id
          const child = propTree[i].child_property_type_id
          if (paren === a.property_type_id && child === b.property_type_id) {
            return 1
          } else if (paren === b.property_type_id && child === b.property_type_id) {
            return -1
          }
        }
      }
      return a.rank - b.rank
    })
  },

  THDates (displaydate, colspan, arrayTHTimes) {
    this.displaydate = displaydate
    this.colspan = colspan
    this.arrayTHTimes = arrayTHTimes
  },

  THTimes (displaytime, colspan, arrayTimestamp) {
    this.displaytime = displaytime
    this.colspan = colspan
    this.arrayTimestamp = arrayTimestamp
  },

  TableData (severityAvg, numObs, propQuantiles, timestamp, propertyAverage) {
    this.severityAvg = severityAvg
    this.numObs = numObs
    this.propQuantiles = propQuantiles
    this.timestamp = timestamp
    this.propertyAverage = propertyAverage
  },

  PropertyJobTable (arrayTHDates, arrayTHTimes, tableRowMap) {
    /**
     * @param {Array} arrayTHDates Array of table header dates (see THDates Object)
     * @param {Array} arrayTHTimes Array of table header times (see THTimes Object)
     * @param {Map} tableRowMap Map propid to row
     */
    this.arrayTHDates = arrayTHDates
    this.arrayTHTimes = arrayTHTimes
    this.tableRowMap = tableRowMap
  },

  jobDataToTable (jobObj) {
    // This function assumes that properties are already sorted and jobObj is not empty
    const [propertyJobTable, tsSet] = this.jobDataToBasicTable(jobObj)

    // add missing (grey) tds in available properties
    for (const row of propertyJobTable.tableRowMap.values()) {
      if (tsSet.size !== row.length) {
        tsSet.forEach(ts => {
          const found = row.find(td => { return td.timestamp === ts })
          if (found === undefined) {
            this.binaryInsertion(row, new this.TableData(-1, 0, [], ts, 0))
          }
        })
      }
    }

    return propertyJobTable
  },

  jobDataToBasicTable (jobObj) {
    // This function assumes that properties are already sorted and jobObj is not empty

    const propertyJobTable = new this.PropertyJobTable([], [], new Map())
    const dateMap = new Map() // helper map
    const tsSet = new Set() // helper set

    jobObj.forEach(tsRow => {
      const numObs = tsRow.num_of_observations

      // store quantiles
      const quantiles = []

      for (let i = 0; i < 11; i++) {
        const quantIdx = i * 10
        quantiles.push(tsRow[`quantile${quantIdx}`])
      }

      // define table data
      const td = new this.TableData(tsRow.severity_average, numObs, quantiles, tsRow.timestamp, tsRow.average)

      // define rows also as objects (only needed for the pop up info)
      const arrayTD = propertyJobTable.tableRowMap.get(tsRow.property_type_id)
      if (arrayTD === undefined) {
        propertyJobTable.tableRowMap.set(tsRow.property_type_id, [td])
      } else {
        this.binaryInsertion(arrayTD, td)
      }
      const tsSetSize = tsSet.size
      tsSet.add(td.timestamp)
      if (tsSet.size === tsSetSize) {
        // no addition was made
        return
      }

      // fill in thdates and thtimes
      const date = new Date(td.timestamp)
      const displaydate = date.toString().split(' ').slice(0, 4).join(' ')
      const displaytime = date.toString().split(' ')[4].split(':')[0] + ':00'
      let thDates = dateMap.get(displaydate)
      if (thDates === undefined) {
        thDates = new this.THDates(displaydate, 1, [])
        dateMap.set(displaydate, thDates)
        thDates.arrayTHTimes = [new this.THTimes(displaytime, 1, [td.timestamp])]
      } else {
        const thTimes = thDates.arrayTHTimes.find((item) => item.displaytime === displaytime)
        if (thTimes === undefined) {
          thDates.arrayTHTimes.push(new this.THTimes(displaytime, 1, [td.timestamp]))
          thDates.colspan++
        } else {
          thDates.colspan++
          thTimes.colspan++
          thTimes.arrayTimestamp.push(td.timestamp)
        }
      }
    })
    propertyJobTable.arrayTHDates = Array.from(dateMap.values())
    propertyJobTable.arrayTHDates.forEach(
      date => { propertyJobTable.arrayTHTimes = [...propertyJobTable.arrayTHTimes, ...date.arrayTHTimes] })

    return [propertyJobTable, tsSet]
  },
  monthlySummaryMakeKey ({ batchdomain, projectId, userId, startDate, endDate }) {
    return JSON.stringify({ batchdomain, projectId, userId, startDate, endDate })
  },
  filteredSearchMakeKey ({ batchdomain, projectId, userId, startDate, startTime, endDate, endTime, limit, jobClass }) {
    return JSON.stringify({ batchdomain, projectId, userId, startDate, startTime, endDate, endTime, limit, jobClass })
  }
}
