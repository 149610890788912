<template>
  <div>
    <b-modal ref="searchAccModal" title="Detailed Accounting" size="lg">
      <!-- Inputs for job search details -->
      <b-form inline class="mb-2">
        <b-container>
          <b-row>
            <b-col>
       <b-form-group label="Batchdomain:" label-cols-sm="6">
         <b-form-radio-group
           v-model="form.data.batchdomain"
           :options="form.input.batchdomain"
           value-field="item"
           text-field="name"
           disabled-field="notEnabled"
         ></b-form-radio-group>
       </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
       <!-- Job Class -->
       <b-button v-b-toggle.collapse-2 variant="primary" v-b-tooltip.hover title="Click to see/hide choices" >Job Class: {{ form.knobs.jobClassAll ? 'All' : 'Filtered' }} </b-button>
            </b-col>
          </b-row>
        </b-container>
        <b-collapse id="collapse-2" class="mt-2">
          <b-card>
            <b-form-group>
              <template #label>
                <b-form-checkbox
                  v-model="form.knobs.jobClassAll"
                  :indeterminate="form.knobs.jcindeterminate"
                  aria-describedby="form.input.jobClasses"
                  aria-controls="form.input.jobClasses"
                  @change="toggleJCAll"
                >
         {{ form.knobs.jobClassAll ? 'Un-select some' : 'Select all or at least one' }}
                </b-form-checkbox>
              </template>

              <template v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="jobclass"
                  v-model="form.data.jobClass"
                  :options="form.input.jobClasses"
                  :aria-describedby="ariaDescribedby"
                  name="Job Class"
                  class="ml-4"
                  aria-label="JobClass"
                  :state="jobClassValid"
                ></b-form-checkbox-group>
              </template>
          </b-form-group>
        </b-card>
      </b-collapse>
      </b-form>

      <b-input-group prepend="Project IDs">
        <b-form-input
          v-model="form.data.projectId"
          placeholder="Project Ids comma separated"
        ></b-form-input>
      </b-input-group>

      <b-input-group prepend="User Ids" class="mt-2">
        <b-form-input
          v-model="form.data.userId"
          placeholder="User Ids comma separated"
        ></b-form-input>
      </b-input-group>

    <!-- Max number of jobs -->
      <b-form inline>
        <b-input-group prepend="Max number of jobs" class="mb-2 mr-5 mt-2">
          <b-form-input
            type="number"
            v-model="form.data.limit"
            min="0"
            :state="jobCountValidation"
          ></b-form-input>
        </b-input-group>

        <div v-if="!jobCountValidation" style="color:darkorange;"><b-icon icon="exclamation-triangle-fill" variant="warning"/> Requesting more than 15000 jobs with performance may take long to fetch.</div>
      </b-form>

      <b-form inline>
      <b-form-group label="Start of End Date/Time" class="mr-2 mt-4">
        <b-form-datepicker v-model="form.data.startDate"></b-form-datepicker>
        <b-form-timepicker v-model="form.data.startTime"></b-form-timepicker>
      </b-form-group>

      <b-form-group label="End of End Date/Time" class="mt-4">
        <b-form-datepicker v-model="form.data.endDate"></b-form-datepicker>
        <b-form-timepicker v-model="form.data.endTime"></b-form-timepicker>
      </b-form-group>
      </b-form>

      <!-- Action buttons on the modal footer -->
      <template v-slot:modal-footer>
        <b-button variant="info" @click="search">Search</b-button>
        <b-button variant="outline-danger" @click="hide">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required, minLength, between } from 'vuelidate/lib/validators'
import utils from '@/common/utils'
import batchids from '@/common/batchids'

export default {
  name: 'search-filter-acc',
  data () {
    return {
      form: {
        data: {
          batchdomain: batchids.getDefaultCluster(),
          projectId: '',
          userId: '',
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
          limit: 15000,
          jobClass: batchids.getJobClass(batchids.getDefaultCluster()) // ['general', 'large', 'fat', 'micro', 'test', 'preempt'],
          // state: ['TIMEOUT', 'COMPLETED', 'FAILED', 'CANCELLED', 'NODE_FAIL', 'OUT_OF_MEMORY', 'RESIZING', 'DEADLINE', 'REQUEUED', 'PREEMPTED'],
        },
        knobs: {
          jcindeterminate: false,
          jobClassAll: true
          // stateAll: true
        },
        input: {
          batchdomain: batchids.getBatchdomains(),
          jobClasses: batchids.getJobClass(batchids.getDefaultCluster()) // ['general', 'large', 'fat', 'micro', 'test', 'preempt'],
        }
      }
    }
  },

  // TODO
  validations: {
    form: {
      data: {
        batchdomain: {
          required
        },
        jobClass: {
          required,
          minLength: minLength(1)
        },
        limit: {
          required,
          between: between(0, 15000)
        }
      }
    }
  },

  computed: {
    jobCountValidation () {
      return this.form.data.limit < 15001
    },
    selectedJobClasses () {
      return this.form.data.jobClass
    },
    jobClassValid () {
      return this.form.data.jobClass.length > 0
    }
  },

  methods: {
    show () {
      this.$refs.searchAccModal.show()
    },

    hide () {
      this.$refs.searchAccModal.hide()
    },

    async search () {
      const self = this
      this.hide()
      if (!self.validEntries()) return
      const filter = utils.removeEmptyValues(self.form.data)
      if (self.form.knobs.jobClassAll && 'jobClass' in filter) {
        delete filter.jobClass
      }
      // all required data is fetched navigate user to job view
      if (this.$route.name === 'JobsAO') {
        this.$router.replace({ name: 'JobsAO', query: filter }) // .catch(_ => { })
        this.$router.go()
      } else {
        this.$router.push({ name: 'JobsAO', query: filter }) // .catch(_ => { })
      }
    },

    validEntries () {
      if (!this.$v.form.data.limit.required || !this.$v.form.data.limit.between) {
        alert('Please give a maximum number of jobs which is equal or less than 15000.')
        return false
      }
      return true
    },

    toggleJCAll (checked) {
      this.form.data.jobClass = checked ? this.form.input.jobClasses.slice() : []
    }
  },

  watch: {
    selectedJobClasses (newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.form.knobs.jcindeterminate = false
        this.form.knobs.jobClassAll = false
      } else if (newValue.length === this.form.input.jobClasses.length) {
        this.form.knobs.jcindeterminate = false
        this.form.knobs.jobClassAll = true
      } else {
        this.form.knobs.jcindeterminate = true
        this.form.knobs.jobClassAll = false
      }
    }
  }

}
</script>
