<template>
  <div>
    <!-- Top Navigation Bar -->
    <b-navbar toggleable="lg" type="dark" variant="secondary" class="d-flex">
      <a href="https://www.lrz.de/" target="_blank"><b-img :src="require('../assets/logo.png')" height="25px" rounded left class="mr-4"/></a>
      <b-navbar-brand href="/" class="mr-3">HPC Report</b-navbar-brand>

      <div class="mx-auto">
        <!--span style="color:white;">SuperMUC-NG:</span>

        <b-button-group class="ml-2">
          <b-button class="mr-1" @click="openJobSearchFilter" variant="light">
          Search Job Ids
          </b-button>

          <b-button @click="openSearchFilter" variant="light">
          Filtered Search
          </b-button>
        </b-button-group-->

        <b-dropdown id="dropdown-1" text="SuperMUC-NG" class="m-md-2">
          <!--b-dropdown-item disabled>Accounting</b-dropdown-item-->
          <b-dropdown-item @click="openSearchAcc">Detailed Accounting</b-dropdown-item>
          <b-dropdown-item @click="openMonthlySearch">Monthly Overview</b-dropdown-item>
          <!--b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item disabled>Extended job info</b-dropdown-item>
          <b-dropdown-item @click="openJobSearchFilter">Search Job Ids</b-dropdown-item>
          <b-dropdown-item @click="openSearchFilter">Filtered Search</b-dropdown-item-->
        </b-dropdown>

        <!--b-button class="mr-2" @click="openStatisticQuery" variant="light">
        Job Statistics
        </b-button-->
      </div>

      <b-button variant="light" class="mr-2" @click="logout" right>
        <b-icon icon="power" aria-hidden="true"></b-icon> Logout
      </b-button>

    </b-navbar>

    <!-- Accounting Filter -->
    <search-filter-acc ref="searchFilterAcc" @fetched="changeFetchingStatus"></search-filter-acc>

    <!-- Monthly Search Filter -->
    <search-monthly-summary ref="searchMonthlyAcc"></search-monthly-summary>

    <!-- Search Filter -->
    <search-job-ids ref="searchJobs" @fetched="changeFetchingStatus"></search-job-ids>

    <!-- Job Filter -->
    <search-filter ref="searchFilter" @fetched="changeFetchingStatus"></search-filter>

    <!-- Job Statistics -->
    <statistic-query ref="statisticQuery"></statistic-query>

    <!-- Spinner -->
    <b-modal
      ref="loadingModal"
      no-close-on-esc
      no-close-on-backdrop
      centered
      hide-header-close>
      <template #modal-title>
        <span class="bold"><b-icon icon="server" class="mr-2"/>
        Loading performance data ...</span>
      </template>
      <div class="d-block text-center">
        <b-spinner variant="secondary" class="m-2" style="width: 3rem; height: 3rem;" ></b-spinner>
      </div>
      <!-- Action buttons on the modal footer -->
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" @click="redirect">Cancel</b-button>
      </template>
    </b-modal>

    <!-- Spinner Timeline -->
    <b-modal
      ref="timelineModal"
      no-close-on-esc
      no-close-on-backdrop
      centered
      hide-header-close>
      <template #modal-title>
        <span class="bold"><b-icon icon="calendar-3" class="mr-2"/>
        Loading and creating timeline</span>
      </template>
      <div class="d-block text-center">
        <b-spinner variant="secondary" class="m-2" style="width: 3rem; height: 3rem;" ></b-spinner>
      </div>
      <!-- Action buttons on the modal footer -->
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" @click="redirect">Cancel</b-button>
      </template>
    </b-modal>

    <!-- Home Child Route Views -->
    <router-view @fetched="changeFetchingStatus" @timeline="toggleModal"></router-view>
  </div>
</template>

<script>
import errorcommon from '@/common/error.js'

export default {
  name: 'Home',
  data () {
    return {
      fetchingStatus: 'Done'
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('refreshAPI')
    } catch (error) {
      errorcommon.handleError(error, this.$router)
    }
  },
  methods: {
    refresh () {
      const self = this
      window.APIClient.refresh().catch(error => errorcommon.handleError(error, self.$router))
    },
    openJobSearchFilter (evt) {
      // stop click event from traversing
      // further upwards in the dom tree
      evt.preventDefault()

      this.refresh()

      // display the search filter
      this.$refs.searchJobs.show()
    },
    openSearchAcc (evt) {
      // stop click event from traversing
      // further upwards in the dom tree
      evt.preventDefault()

      this.refresh()

      // display the search filter
      this.$refs.searchFilterAcc.show()
    },
    openMonthlySearch (evt) {
      evt.preventDefault()
      this.refresh()
      this.$refs.searchMonthlyAcc.show()
    },
    openSearchFilter (evt) {
      // stop click event from traversing
      // further upwards in the dom tree
      evt.preventDefault()

      this.refresh()

      // display the search filter
      this.$refs.searchFilter.show()
    },
    openStatisticQuery (evt) {
      // stop click event from traversing
      // further upwards in the dom tree
      evt.preventDefault()
      this.refresh()

      // display the search filterä
      this.$refs.statisticQuery.show()
    },
    logout () {
      window.APIClient.logout()
      window.localStorage.clear()
      this.$store.commit('emptyLocalStorage')
      this.$store.commit('emptyAccAndPerfData')
      this.$store.commit('setSearchURL', '')
      this.$router.push({ name: 'Login' })
    },
    changeFetchingStatus (status) {
      this.fetchingStatus = status
      if (status === 'Perf') {
        this.showLoading()
      } else {
        this.hideLoading()
      }
    },
    showLoading () {
      this.$refs.loadingModal.show()
    },
    hideLoading () {
      this.$refs.loadingModal.hide()
    },
    toggleModal (status) {
      if (status === 'Create') {
        this.$refs.timelineModal.show()
      } else {
        this.$refs.timelineModal.hide()
      }
    },
    redirect () {
      this.$refs.loadingModal.hide()
      this.$refs.timelineModal.hide()
      this.$store.commit('cancel')
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>
span.bold {
  font-size: 20px;
  font-weight: 700;
}
</style>
