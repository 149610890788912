<template>
  <div>
  <b-container fluid>
    <b-row><br></b-row>
    <b-row><b-col><h3>Accounting Monthly Overview</h3></b-col></b-row>
    <b-row>
      <b-col v-if="booltitle"><h3>{{ getTitle() }}</h3></b-col>
    </b-row>
    <b-row><br></b-row>
        <!-- Table Controls -->
    <b-row v-if="hasItems">
      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="table.currentPage"
          :total-rows="table.totalRows"
          :per-page="table.perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
      <b-col><h5>Total Used Budget: {{ getTotalBudget() }}</h5></b-col>
    </b-row>

    <b-row>
      <b-col>
        <!-- Summary Information Table -->
        <b-table
          class="summary-table text-nowrap"
          striped
          hover
          bordered
          outlined
          responsive
          selectable
          selected-variant="primary"
          sticky-header="90vh"
          small
          :items="table.items"
          :per-page="table.perPage"
          :currentPage="table.currentPage"
          :fields="table.fields"></b-table>
      </b-col>
      <b-col><br><b-button @click="copy()"><b-icon icon="clipboard"/> Copy in CSV format</b-button></b-col>
    </b-row>
    <b-row><b-col><div style="border: 1px solid black;" ref="graphdiv"></div></b-col></b-row>
    </b-container>
    <!-- Spinner -->
    <b-modal
    ref="loadingModal"
    no-close-on-esc
    no-close-on-backdrop
    centered
    hide-header-close>
    <template #modal-title>
      <span class="bold"><b-icon icon="server" class="mr-2"/>
      Loading Monthly Summary </span>
    </template>
    <div class="d-block text-center">
      <b-spinner variant="secondary" class="m-2" style="width: 3rem; height: 3rem;" ></b-spinner>
    </div>
    <!-- Action buttons on the modal footer -->
    <template v-slot:modal-footer>
      <b-button variant="outline-danger" @click="cancel">Cancel</b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>

import errorcommon from '@/common/error'
import batchids from '@/common/batchids'
import utils from '@/common/utils'
import Dygraph from 'dygraphs'

export default {
  name: 'acc-summary',

  computed: {
    hasItems () {
      return this.table.items.length > 0
    }
  },

  data () {
    return {
      table: {
        items: [], // initialized on component mount
        fields: [], // initialized through items
        perPage: 12, // number of rows to display by default
        currentPage: 1, // start at first page
        totalRows: -1 // initialized on component mount
      },
      booltitle: false,
      isGPU: false
    }
  },

  async mounted () {
    const self = this
    const batchdomain = self.$route.query.batchdomain
    const projectid = self.$route.query.projectId
    if (!batchdomain || !projectid) {
      console.log('Batch domain or project id missing')
      return
    }
    let items = self.$store.getters.getMonthlySummary(self.$route.query)
    if (!items) {
      self.$refs.loadingModal.show()
      self.booltitle = false
      try {
        if (batchids.getPuType(batchdomain) === 'CPU') {
          await self.$store.dispatch('fetchMonthlySummaryCPU', self.$route.query)
        } else {
          self.isGPU = true
          await self.$store.dispatch('fetchMonthlySummaryGPU', self.$route.query)
        }
      } catch (error) {
        errorcommon.handleError(error, self.$route)
      } finally {
        self.booltitle = true
        self.$refs.loadingModal.hide()
      }
    }
    items = self.$store.getters.getMonthlySummary(self.$route.query)
    if (items && items.length > 0) {
      self.initTable(items)
    } else {
      self.table.items = []
    }
  },

  methods: {
    initTable (items) {
      const self = this
      self.table.items = items
      this.table.totalRows = this.table.items.length
      // generate table column header fields from first row
      this.table.fields = Object.keys(items[0]).map(function (item, index, array) {
        if (item === 'month') {
          return {
            key: item,
            label: 'Month',
            sortable: true,
            stickyColumn: true
          }
        } else if (item === 'sum_gpuhours') {
          return {
            key: item,
            label: 'Sum GPU-Hours',
            sortable: true
          }
        } else if (item === 'sum_corehours') {
          return {
            key: item,
            label: 'Sum Core-Hours',
            sortable: true
          }
        }
        return {
          key: item,
          sortable: true
        }
      })
      this.makeGraph(this.$refs.graphdiv)
    },
    getTitle () {
      const self = this
      if (!self.table.items || self.table.items.length === 0 || !self.$route.query.projectId) return 'No data found'
      let title = 'Project: ' + self.$route.query.projectId
      if (self.$route.query.userId) {
        title += ' User: ' + self.$route.query.userId
      }
      if (self.$route.query.startDate) {
        title += ' From: ' + self.$route.query.startDate
      }
      if (self.$route.query.endDate) {
        title += ' To: ' + self.$route.query.endDate
      }
      return title
    },
    cancel () {
      self.booltitle = true
      this.$refs.loadingModal.hide()
    },
    getTotalBudget () {
      let total = 0
      this.table.items.forEach(item => {
        const values = Object.values(item)
        // Access Month as values[0] and Core/Gpu hours as values[1]
        total += parseFloat(values[1])
      })
      const mystr = `${utils.humanReadable(Math.floor(total))}` + (this.isGPU ? ' GPU-Hours' : ' CPU-Hours')
      return mystr
    },
    makeGraph (graphref) {
      const self = this
      const mywidth = Math.round(window.innerWidth * 0.9)
      const myheight = Math.round(window.innerHeight * 0.4)
      const graphColors = ['#0033CC', '#6666CC', '#66FFFF', '#33FF99', '#00CC33', '#006600', '#CCFF66', '#FFFF00', '#FF9900', '#FF3300', '#CC0000']
      const options = {
        colors: graphColors,
        height: myheight,
        width: mywidth,
        axes: {
          y: {
            axisLabelWidth: 60
          }
        }
      }

      const csvdata = self.getCSVData()

      const graphObj = new Dygraph(
        graphref,
        csvdata,
        options
      )
      return graphObj
    },
    getCSVData () {
      let csv = 'Month,Budget\n'
      this.table.items.forEach(item => {
        const values = Object.values(item)
        // Access Month as values[0] and Core/Gpu hours as values[1]
        csv += `${values[0]},${values[1]}\n`
      })
      return csv
    },
    async copy () {
      const copytext = this.getCSVData()
      await navigator.clipboard.writeText(copytext)
    }
  }
}
</script>

<style>
.summary-table thead th:first-child {
  z-index: 10 !important;
}
</style>
