import axios from 'axios'

export default {
  state: {
    auth: {
      textStatus: '',
      state: false
    }
  },

  getters: {
    isLoggedIn (state) {
      return state.auth.state
    },

    getAccessToken (state) {
      return window.localStorage.getItem('accessToken') || ''
    },

    getRefreshToken (state) {
      return window.localStorage.getItem('refreshToken') || ''
    }
  },

  mutations: {
    authSuccess (state, { access, refresh }) {
      state.auth.state = true
      state.textStatus = 'authenticated'

      window.localStorage.setItem('accessToken', access)
      window.localStorage.setItem('refreshToken', refresh)
    },

    authError (state) {
      state.auth.state = false
      state.textStatus = 'not authenticated'
    }
  },

  actions: {
    login ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_BASE_URL}/token/`, data)
          .then(response => {
            const access = response.data.access
            const refresh = response.data.refresh

            commit('authSuccess', { access, refresh })
            // TODO fetch user data and store it
            resolve(response)
          }).catch(error => {
            commit('authError')
            reject(error)
          })
      })
    },

    refresh ({ commit, getters }) {
      return new Promise((resolve, reject) => {
        const refresh = getters.getRefreshToken
        if (refresh) {
          axios.post(`${process.env.VUE_APP_API_BASE_URL}/token/refresh/`, { refresh })
            .then(response => {
              // save the new access token
              const access = response.data.access
              // axios.defaults.headers.common.Authorization = access
              commit('authSuccess', { access, refresh })
              resolve(response)
            }).catch(error => {
              reject(error)
            })
        } else {
          reject(new Error('No refresh token stored, need to login first'))
        }
      })
    },

    logout ({ commit }) {
      // delete auth token from localStorage
      // delete accounting data and performance data from localStorage
      // reset auth state
    }
  }
}
