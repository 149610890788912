<template>
  <b-button class="m-md-2" size="sm" @click="goBack()"><b-icon icon="caret-left-fill" class="ml-2"/>Back</b-button>
</template>

<script>
export default {
  name: 'goback',
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
