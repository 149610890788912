<template>
  <div>
  <br>
  <h3>Sorry, your session timed out!</h3>
  <h3>Please login again.</h3>
  <br>
   <b-button @click="gotoLogin">Go to Login</b-button>
  </div>
</template>

<script>

export default {
  name: 'session-timeout',

  methods: {
    gotoLogin () {
      this.$router.push({ name: 'Login' }).catch(_ => {})
    }
  }
}
</script>

<style scopde>
</style>
