import { render, staticRenderFns } from "./JobAccOnly.vue?vue&type=template&id=568ff784&scoped=true"
import script from "./JobAccOnly.vue?vue&type=script&lang=js"
export * from "./JobAccOnly.vue?vue&type=script&lang=js"
import style0 from "./JobAccOnly.vue?vue&type=style&index=0&id=568ff784&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "568ff784",
  null
  
)

export default component.exports