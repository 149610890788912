import storeFuncts from './storeFuncts'

export default {
  setProperties (state, { properties }) {
    state.properties = properties
  },

  setPropertyTree (state, { propertyTree }) {
    state.propertyTree = propertyTree
  },

  setJob (state, { jobId, job }) {
    state.jobPerfData[jobId] = job
  },

  setJobScript (state, { jobId, script }) {
    state.jobScript[jobId] = script
  },

  setPropertyJobTable (state, { jobId, jobPropertyTable }) {
    state.propertyJobTable[jobId] = jobPropertyTable
  },

  setAccounting (state, { key, accountings }) {
    state.accountings[key] = accountings
  },

  setPropertiesSorted (state) {
    state.propertiesSorted = true
  },

  setMonthlySummary (state, { key, sumlist }) {
    /* sumlist should look like this:
    [ {
        "month": "2019-08-01",
        "total_corehours": "5976665.52"
      },
      {
        "month": "2019-09-01",
        "total_corehours": "17793491.31"
      },
      {
        "month": "2019-10-01",
        "total_corehours": "6829002.39"
      },
      ...
    ] */
    state.monthlySummary[key] = sumlist
  },

  storeJobId (state, { jobId }) {
    // check if there are any job ids stored in localStorage
    if (window.localStorage.getItem(storeFuncts.LOCAL_STORAGE.JOBIDS)) {
      // there are job ids stored, get them
      const jobIds = JSON.parse(window.localStorage.getItem(storeFuncts.LOCAL_STORAGE.JOBIDS))
      // check if the id is already stored
      if (jobIds.indexOf(jobId) === -1) {
        // the job id is not stored in localStorage yet, add it
        jobIds.push(jobId)
        // updatet localStorage
        window.localStorage.setItem(storeFuncts.LOCAL_STORAGE.JOBIDS, JSON.stringify(jobIds))
      }
    } else {
      // there are no job ids, create a new job id array in localStorage
      window.localStorage.setItem(storeFuncts.LOCAL_STORAGE.JOBIDS, JSON.stringify([jobId]))
    }
  },

  storeJobWithPerf (state, { jobId }) {
    const jobFound = state.storePerfJobs.find(jid => { return jid === jobId })
    if (!jobFound) {
      state.storePerfJobs.push(jobId)
    }
    // check if there are any job ids stored in localStorage
    if (window.localStorage.getItem(storeFuncts.LOCAL_STORAGE.PERF_JOBS)) {
      // there are job ids stored, get them
      const jobIds = JSON.parse(window.localStorage.getItem(storeFuncts.LOCAL_STORAGE.PERF_JOBS))
      // check if the id is already stored
      if (jobIds.indexOf(jobId) === -1) {
        // the job id is not stored in localStorage yet, add it
        jobIds.push(jobId)
        // updatet localStorage
        window.localStorage.setItem(storeFuncts.LOCAL_STORAGE.PERF_JOBS, JSON.stringify(jobIds))
      }
    } else {
      // there are no job ids, create a new job id array in localStorage
      window.localStorage.setItem(storeFuncts.LOCAL_STORAGE.PERF_JOBS, JSON.stringify([jobId]))
    }
  },

  setSearchURL (state, url) {
    window.localStorage.setItem(storeFuncts.LOCAL_STORAGE.SEARCHURL, url)
  },

  emptyLocalStorage (state) {
    window.localStorage.setItem(storeFuncts.LOCAL_STORAGE.JOBIDS, JSON.stringify([]))
    window.localStorage.setItem(storeFuncts.LOCAL_STORAGE.PERF_JOBS, JSON.stringify([]))
    state.monthlySummary = {}
    state.storePerfJobs = []
  },

  emptyPerfJobs (state) {
    window.localStorage.setItem(storeFuncts.LOCAL_STORAGE.PERF_JOBS, JSON.stringify([]))
    state.storePerfJobs = []
  },

  emptyAccAndPerfData (state) {
    state.jobPerfData = {}
    state.propertyJobTable = {}
    state.accountings = {}
    state.monthlySummary = {}
  },

  copyPerfJobsToJobIds (state) {
    const perfjobs = window.localStorage.getItem(storeFuncts.LOCAL_STORAGE.PERF_JOBS)
    window.localStorage.setItem(storeFuncts.LOCAL_STORAGE.JOBIDS, perfjobs)
  },

  cancel (state) {
    state.cancel = true
  },

  resetCancel (state) {
    state.cancel = false
  },

  setModules (state, { jobId, modules }) {
    state.jobScript[jobId] = modules
  }
}
