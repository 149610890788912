<template>
    <div>
      <b-container fluid>
        <b-row> <b-col>
          <b-card title="Job General Info">
            <b-card-text>
            <b-table stacked :items="table.items"></b-table>
            </b-card-text>
          </b-card>
        </b-col></b-row>
        <b-row> <b-col>
          <b-card title="Modules loaded">

          </b-card>
        </b-col></b-row>
      </b-container>
    </div>
</template>

<script>
import errorcommon from '@/common/error.js'
export default {
  name: 'JobInfoView',
  data () {
    return {
      jobId: undefined,
      jobAccounting: undefined,
      modules: undefined,
      table: {
        items: []
      }
    }
  },
  async mounted () {
    const self = this
    const jobId = self.jobId = self.$router.currentRoute.params.jobId
    self.jobAccounting = this.$store.getters.getJobAccounting(this.jobId)
    if (!self.jobAccounting) {
      self.$store.dispatch('fetchAccountingFromJob', { jobId })
        .then(resolve => { self.jobAccounting = this.$store.getters.getJobAccounting(this.jobId) })
        .catch(error => { errorcommon.handleError(error, self.$router) })
    } else {
      self.initTable(self.table.items)
    }
  },
  computed: {
    getJobName () {
      if (this.jobAccounting) {
        return this.jobAccounting.job_name.length > 48 ? this.jobAccounting.job_name.substring(0, 45) + '...' : this.jobAccounting.job_name
      }
      return 'No data'
    }
  },
  methods: {
    initTable (items) {
      const self = this
      this.table.items = [
        {
          'Job Id': self.jobAccounting.job_id_string,
          'User Id': self.jobAccounting.user,
          'Project Id': self.jobAccounting.project,
          'Number of nodes': self.jobAccounting.nodes,
          'Job Name': self.jobAccounting.job_name,
          'MaxRSS [KB/Node]': self.jobAccounting.maxrss,
          'User Time': self.jobAccounting.user_time,
          'System Time': self.jobAccounting.system_time,
          'Average Frequency': self.jobAccounting.frequency
          /*
        <strong>Average Power:</strong> {{ getAveragePower }} <br>
        <strong>Energy Consumption:</strong> {{ getEnergyConsumption }} */
        }
      ]
    }
  }
}
</script>
