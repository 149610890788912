import axios from 'axios'

export class APIClient {
  constructor () {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL
    })
  }

  getAuthHeader () {
    const accessToken = window.localStorage.getItem('accessToken')
    return {
      Authorization: `Bearer ${accessToken}`
    }
  }

  login (username, password) {
    return new Promise((resolve, reject) => {
      this.client.post('/token/', { username, password }).then(response => {
        window.localStorage.setItem('accessToken', response.data.access)
        window.localStorage.setItem('refreshToken', response.data.refresh)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }

  logout () {
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('refreshToken')
  }

  refresh () {
    const payload = {
      refresh: window.localStorage.getItem('refreshToken')
    }
    return new Promise((resolve, reject) => {
      this.client.post('/token/refresh/', payload).then(response => {
        window.localStorage.setItem('accessToken', response.data.access)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }

  get (path) {
    const config = { headers: this.getAuthHeader() }
    return new Promise((resolve, reject) => {
      this.client.get(path, config).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  post (path, payload) {
    const config = { headers: this.getAuthHeader() }
    return new Promise((resolve, reject) => {
      this.client.post(path, payload, config).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  put (path, payload) {
    const config = { headers: this.getAuthHeader() }
    return new Promise((resolve, reject) => {
      this.client.put(path, payload, config).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  patch (path, payload) {
    const config = { headers: this.getAuthHeader() }
    return new Promise((resolve, reject) => {
      this.client.patch(path, payload, config).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  delete (path) {
    const config = { headers: this.getAuthHeader() }
    return new Promise((resolve, reject) => {
      this.client.delete(path, config).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
