<template>
  <div>
   <b-modal ref="avgTableHelpModal" title="Performance Overview Table">
     This table shows a job per row and the average severity of its collected performance metrics. The severity ranges from 0 to 1, and the value is shown on the table cell.
     The performance is considered "good" when the severity is 0 or "bad" when the severity is 1 and is coded in color from green to red respectively. Note that this classification is only a hint that optimization is needed. Depending on what the job does, it might not be possible to optimize.
     <table border="1" style="text-align: left;">
       <tr>
         <td>Color:</td>
         <td v-for="index in 11" :key="index" :style="getColor((index-1)/10)">
          <b-icon icon="stop-fill" size="lg"/>
         </td>
       </tr>
         <td>Severity:</td>
         <td v-for="index in 11" :key="index">{{ (index-1)/10 }} </td>
       <tr>
       </tr>
     </table>
     If the measurement is missing you will see it grey: <span class="h4"> <b-icon icon="stop-fill" :style="getColor(2)"/></span> <br>
   </b-modal>
  </div>
</template>

<script>
export default {
  name: 'avg-table-help',
  data () {
    const mC = new Map([
      [-20, [211, 211, 211]],
      [0, [128, 243, 32]],
      [1, [141, 237, 24]],
      [2, [154, 230, 17]],
      [3, [166, 222, 11]],
      [4, [178, 213, 7]],
      [5, [190, 203, 4]],
      [6, [201, 192, 2]],
      [7, [211, 181, 1]],
      [8, [221, 169, 2]],
      [9, [229, 156, 4]],
      [10, [236, 143, 7]],
      [11, [242, 130, 12]],
      [12, [247, 118, 17]],
      [13, [251, 105, 24]],
      [14, [254, 92, 32]],
      [15, [255, 80, 41]],
      [16, [255, 68, 51]],
      [17, [253, 57, 62]],
      [18, [251, 47, 73]],
      [19, [247, 37, 85]],
      [20, [241, 29, 97]]
    ])
    return {
      mapColor: mC
    }
  },
  methods: {
    numToRgb (val) {
      const arr = this.mapColor.get(Math.round(val * 20))
      if (arr === undefined) {
        return [211, 211, 211]
      }
      return arr
    },
    getColor (val) {
      const color = this.numToRgb(val)
      return `color: rgba(${color}); fontsize: large`
    },
    show () {
      this.$refs.avgTableHelpModal.show()
    },

    hide () {
      this.$refs.avgTableHelpModal.hide()
    }
  }
}
</script>

<style scoped>
</style>
