
export default {
  handleError (error, router) {
    if (error.message.includes('status code 403') || error.message.includes('status code 401')) {
      if (router.name !== 'SessionTimeOut') { // Show only once...
        router.push({ name: 'SessionTimeOut' }).catch(_ => {})
      }
    } else if (error.message.includes('Network Error')) {
      alert('Sorry, we lost connection to our database. Please try again. If the problem persists please contact us via our Servicedesk, and copy this message.')
    } else {
      let consmessage = error.name + ' ' + error.message
      if (error.stack) {
        consmessage += error.stack
      }

      if (router.name === 'Login') {
        // console.log(consmessage)
        return
      }
      alert('Sorry! Something went wrong. Please contact us via our Servicedesk and copy this message: ' + consmessage)
    }
  }
}
