<template>
  <div>
    <b-modal ref="searchMonthlyModal" title="Monthly Accounting" size="lg">
      <!-- Inputs for job search details -->
      <b-form inline class="mb-2">
        <b-container>
          <b-row>
            <b-col>
              <b-form-group label="Batchdomain:" label-cols-sm="6">
                <b-form-radio-group
                  v-model="form.data.batchdomain"
                  :options="form.input.batchdomain"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  required
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>

      <b-input-group prepend="Project ID">
          <b-form-input
            v-model="form.data.projectId"
            placeholder="Required field"
            required
          ></b-form-input>
        </b-input-group>

        <b-input-group prepend="User Id" class="mt-2">
          <b-form-input
            v-model="form.data.userId"
          ></b-form-input>
        </b-input-group>

        <b-form inline>
        <b-form-group label="Start of End Date/Time" class="mr-2 mt-4">
          <b-form-datepicker v-model="form.data.startDate"></b-form-datepicker>
        </b-form-group>

        <b-form-group label="End of End Date/Time" class="mt-4">
          <b-form-datepicker v-model="form.data.endDate"></b-form-datepicker>
        </b-form-group>
        </b-form>

        <!-- Action buttons on the modal footer -->
        <template v-slot:modal-footer>
          <b-button variant="info" @click="search">Search</b-button>
          <b-button variant="outline-danger" @click="hide">Cancel</b-button>
        </template>
      </b-modal>
  </div>
</template>

<script>

import batchids from '@/common/batchids'
import utils from '@/common/utils'

export default {
  name: 'search-monthly-summary',
  data () {
    return {
      form: {
        data: {
          batchdomain: batchids.getDefaultCluster(),
          projectId: '',
          userId: '',
          startDate: '',
          endDate: ''
        },
        input: {
          batchdomain: batchids.getBatchdomains()
        }
      }
    }
  },

  methods: {
    show () {
      this.$refs.searchMonthlyModal.show()
    },

    hide () {
      this.$refs.searchMonthlyModal.hide()
    },

    async search () {
      const self = this
      if (!self.form.data.projectId || self.form.data.projectId === '') {
        alert('Project id is required')
        return
      }
      if (!self.form.data.batchdomain || self.form.data.batchdomain === '') {
        alert('Please choose a batch domain')
        return
      }
      const projs = self.form.data.projectId.split(',')
      if (projs.length > 1) {
        alert('Please write only one project id!')
        return
      }
      if (self.form.data.userId) {
        const users = self.form.data.userId.split(',')
        if (users.length > 1) {
          alert('Please write maximum one user id!')
          return
        }
      }
      // all required data is fetched navigate user to job view
      const filter = utils.removeEmptyValues(self.form.data)
      // console.log(filter)
      self.hide()
      if (self.$route.name === 'AccSum') {
        self.$router.replace({ name: 'AccSum', query: filter }).catch(_ => { })
        // window.location.reload()
        self.$router.go()
      } else {
        self.$router.push({ name: 'AccSum', query: filter }).catch(_ => { })
      }
    }
  }
}
</script>
