<template>
  <div class="jobinfo">
    <div v-if="jobAccounting">
     <b-button :id="jobId" class="m-md-2" size="sm">Job Info</b-button>
     <b-popover :target="jobId" triggers="click" placement="bottom">
        <template #title>Job Id {{ jobAccounting.job_id_string }} </template>
        User: {{ jobAccounting.user }} <br>
        Project: {{ jobAccounting.project }} <br>
        Nodes used: {{ jobAccounting.nodes }} <br>
        Job Name: {{ getJobName }}
     </b-popover>
    </div>
    <div v-else>
    Error: No job data
    </div>
  </div>
</template>

<script>
import errorcommon from '@/common/error.js'
export default {
  name: 'JobInfo',
  data () {
    return {
      jobId: undefined,
      jobAccounting: undefined
    }
  },
  async mounted () {
    const self = this
    const jobId = self.jobId = self.$router.currentRoute.params.jobId
    self.jobAccounting = this.$store.getters.getJobAccounting(this.jobId)
    if (!self.jobAccounting) {
      self.$store.dispatch('fetchAccountingFromJob', { jobId })
        .then(resolve => { self.jobAccounting = this.$store.getters.getJobAccounting(this.jobId) })
        .catch(error => { errorcommon.handleError(error, self.$router) })
    }
  },
  computed: {
    getTitle () {
      if (this.jobAccounting) {
        return `Job Id ${this.jobAccounting.job_id_string}`
      }
      return 'No job data'
    },
    getJobName () {
      if (this.jobAccounting) {
        return this.jobAccounting.job_name.length > 48 ? this.jobAccounting.job_name.substring(0, 45) + '...' : this.jobAccounting.job_name
      }
      return 'No data'
    }
  }
}
</script>

<style scoped>
.jobinfo {
  max-width: 430px;
}
</style>
