import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.js'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    properties: [],
    propertyTree: [],
    propertiesSorted: false,
    jobPerfData: {},
    propertyJobTable: {},
    accountings: {},
    cancel: false,
    storePerfJobs: [],
    monthlySummary: {},
    jobScript: {},
    modules: {}
  },

  actions,
  getters,
  mutations,
  modules: {
    auth
    // accounting
    // performance
  }
})
