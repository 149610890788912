const clusterconfig = {
  // batchdomain id : [clustername, shortname, [partition list], max_num_nodes, PU-Type]
  1010: ['SuperMUC-NG Phase 1', 'Phase 1', ['general', 'large', 'fat', 'micro', 'test', 'preempt'], 6336, 'CPU'],
  1020: ['SuperMUC-NG Phase 2', 'Phase 2', ['test', 'general', 'large', 'preempt'], 236, 'GPU']
}

const subids = {
  1011: 'fat',
  1012: 'general',
  1013: 'large',
  1014: 'micro',
  1015: 'preempt',
  1016: 'test',
  1017: 'tmpx'
}

export default {

  getBatchname (id) {
    if (!(id in clusterconfig)) {
      return 'Unknown Cluster: ' + id
    }
    return clusterconfig[id][1]
  },

  getAllBatchids () {
    let batches = ''
    for (const key in clusterconfig) {
      batches += key + ','
    }
    batches = batches.slice(0, -1)
    return batches
  },

  getJobClass (id) {
    if (!(id in clusterconfig)) {
      return ['Unknown Cluster: ' + id]
    }
    return clusterconfig[id][2]
  },

  getMaxNodes (id) {
    if (!(id in clusterconfig)) {
      return 'Unknown Cluster: ' + id
    }
    return clusterconfig[id][3]
  },

  getPuType (id) {
    return clusterconfig[id][4]
  },

  getDefaultCluster () {
    return Object.keys(clusterconfig)[0]
  },

  getBatchdomains () {
    const batchdomainvec = []
    for (const key in clusterconfig) {
      batchdomainvec.push({ item: key, name: clusterconfig[key][1] })
    }

    return batchdomainvec
  },

  getBatchdomainsBoth () {
    const batchdomainvec = []
    for (const key in clusterconfig) {
      batchdomainvec.push({ item: key, name: clusterconfig[key][1] })
    }
    batchdomainvec.push({ item: 'Both', name: 'Both' })
    return batchdomainvec
  },

  getSubBatchId (jobclass) {
    for (const key in subids) {
      if (subids[key] === jobclass) {
        return key
      } else if (subids[key].startsWith('tmp') && jobclass.startsWith('tmp')) {
        return key
      }
    }
    return 0
  }
}
